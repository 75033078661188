import { Image } from "@/components/common";
import type { BoxProps } from "@chakra-ui/react";
import { Box, List, ListIcon, ListItem, Text } from "@chakra-ui/react";
import * as React from "react";
import { RiCheckFill } from "react-icons/ri";

export interface UspListProps extends BoxProps {
  uspList: {
    title: string;
    usps: { label: string }[];
    image: {
      dimensions: { width: number; height: number };
      url: string;
    };
  };
}

export const UspList = ({ uspList, ...rest }: UspListProps) => (
  <Box {...rest}>
    <Image src={uspList.image.url} height="32px" width="32px" sizes="32px" alt="" mb={4} />
    <Text fontSize="xl" fontWeight="medium" mb={6}>
      {uspList.title}
    </Text>
    <List spacing={4}>
      {uspList.usps.map((item, index) => (
        <ListItem key={index} display="flex" alignItems="center">
          <ListIcon as={RiCheckFill} fontSize="24px" color="green.600" />
          {item.label}
        </ListItem>
      ))}
    </List>
  </Box>
);
