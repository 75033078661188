import { Carousel } from "@/components/Carousel";
import { SectionDescription, Section, SectionHeading } from "@/components/Section";
import { useBreakpointImage } from "@/hooks/useBreakpointImage";
import { htmlSerializer } from "@/lib/htmlSerializer";
import { Grid, Text } from "@chakra-ui/react";
import { PrismicRichText } from "@prismicio/react";
import * as React from "react";

const SplitImageSection = ({ slice }) => {
  const imagePosition = slice.variation.includes("left") ? "left" : "right";
  const sectionVariant = slice.variation.toLowerCase().includes("cream") ? "cream" : "white";
  const breakpointImage = useBreakpointImage();
  const images =
    slice.items.length > 1
      ? slice.items.map(({ image }) => {
          const newImg = breakpointImage(image);

          return {
            ...newImg,
            src: image.url,
          };
        })
      : slice.primary.image
      ? [{ ...slice.primary.image, src: slice.primary.image.url }]
      : [];

  return (
    <Grid
      minH={{ lg: "718px" }}
      gridTemplateColumns={{
        base: "1fr",
        lg: imagePosition === "left" ? "minmax(0, 720px) minmax(60ch, 1fr)" : "minmax(60ch, 1fr) minmax(0, 720px)",
      }}
      gridTemplateRows={{ base: "max-content 1fr", lg: "1fr" }}
      overflow="hidden"
      maxH={{ base: "100%", lg: "800px" }}
    >
      <Carousel
        display={{ base: "flex", lg: imagePosition === "right" ? "none" : "initial" }}
        _hover={{
          ".carousel-button": {
            opacity: 0.9,
          },
        }}
        images={images}
        height="100%"
        width="100%"
        maxH={{ base: "400px", md: "760px", lg: "800px" }}
        zIndex={1}
        containerProps={{ borderRadius: 0 }}
      />
      <Section variant={sectionVariant} containerProps={{ maxW: { lg: "720px" } }}>
        {slice.primary.tagline ? (
          <Text color="almond" fontWeight="medium" fontSize="xs" textTransform="uppercase" mb={4}>
            {slice.primary.tagline}
          </Text>
        ) : null}
        {slice.primary.title ? (
          <SectionHeading mb={slice.primary.subtitle ? 4 : 8}>{slice.primary.title}</SectionHeading>
        ) : null}
        {slice.primary.subtitle ? (
          <Text fontSize="sm" color="gray.600" mb={8}>
            {slice.primary.subtitle}
          </Text>
        ) : null}
        <SectionDescription mb={0}>
          <PrismicRichText field={slice.primary.description} components={htmlSerializer} />
        </SectionDescription>
      </Section>
      <Carousel
        display={{ base: "none", lg: imagePosition === "right" ? "initial" : "none" }}
        _hover={{
          ".carousel-button": {
            opacity: 0.9,
          },
        }}
        images={images}
        height="100%"
        width="100%"
        maxH={{ base: "400px", md: "760px", lg: "800px" }}
        zIndex={1}
        containerProps={{ borderRadius: 0 }}
      />
    </Grid>
  );
};

SplitImageSection.displayName = SplitImageSection;

export default SplitImageSection;
