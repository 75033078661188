import { Section, SectionDescription, SectionHeading } from "@/components/Section";
import { htmlSerializer } from "@/lib/htmlSerializer";
import { Button } from "@chakra-ui/react";
import Link from "next/link";
import { PrismicRichText } from "@prismicio/react";
import * as React from "react";

const CtaSection = ({ slice }) => {
  const isCenterVariant = slice.variation.toLowerCase().includes("center") ? true : false;
  const sectionVariant = slice.variation.toLowerCase().includes("cream") ? "cream" : "white";

  return (
    <Section
      variant={sectionVariant}
      containerProps={{
        display: "flex",
        flexDirection: "column",
        alignItems: isCenterVariant ? "center" : "flex-start",
      }}
    >
      <SectionHeading maxW={isCenterVariant ? "100%" : "880px"} textAlign={isCenterVariant ? "center" : "start"}>
        {slice.primary.title}
      </SectionHeading>
      <SectionDescription textAlign={isCenterVariant ? "center" : "start"} mb={10}>
        <PrismicRichText field={slice.primary.description} components={htmlSerializer} />
      </SectionDescription>
      <Link href={slice.primary.buttonLink.url} passHref legacyBehavior>
        <Button as="a" variant="primary">
          {slice.primary.buttonLabel}
        </Button>
      </Link>
    </Section>
  );
};

CtaSection.displayName = CtaSection;

export default CtaSection;
