import { Card } from "@/components/common/Card";
import type { TabProps } from "@chakra-ui/react";
import { Button, Text, useTab } from "@chakra-ui/react";
import * as React from "react";

export interface LargeTextTabProps extends TabProps {
  title: string;
  description: string;
}

const LargeTextTab = React.forwardRef(
  ({ title, description, ...rest }: LargeTextTabProps, ref: React.Ref<HTMLElement> | undefined) => {
    const tabProps = useTab({ ...rest, ref });
    const isSelected = Boolean(tabProps["aria-selected"]);

    return (
      <Button {...tabProps}>
        <Card textAlign="left" p={6} className={isSelected ? "selected" : "not-selected"}>
          <Text mb={2} fontSize="xl" fontWeight="medium">
            {title}
          </Text>
          <Text>{description}</Text>
        </Card>
      </Button>
    );
  },
);

LargeTextTab.displayName = "LargeTextTab";

export default LargeTextTab;
