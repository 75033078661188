import { CitiesMenu } from "@/components/CitiesMenu";
import { Container } from "@/components/Container";
import { SectionDescription } from "@/components/Section";
import { Image } from "@/components/common";
import { AMPLITUDE_EVENTS, logEvent } from "@/lib/amplitude";
import { htmlSerializer } from "@/lib/htmlSerializer";
import { Box, Button, Link as ChakraLink, Flex } from "@chakra-ui/react";
import { PrismicRichText } from "@prismicio/react";
import Link from "next/link";
import { useRouter } from "next/router";

export const HeroSection = ({ slice, heroImage }) => {
  const router = useRouter();

  const my = {
    base: slice.variation === "small" ? "10.75rem" : "7.5rem",
    md: slice.variation === "small" ? "10.25rem" : "12.25rem",
  };

  const image = heroImage ?? slice.primary?.image;

  const onHomesButtonClick = () => {
    logEvent(AMPLITUDE_EVENTS.browseButtonClick, { origin: "City hero section", city: router?.query?.uid });
  };

  return (
    <Flex
      as="section"
      position="relative"
      w="100%"
      flexDirection="column"
      maxW="100%"
      background="rgba(34, 29, 16, 0.5)"
      minH={slice.variation === "city" ? "80vh" : undefined}
    >
      <Image
        position="absolute"
        inset={0}
        zIndex={0}
        src={image.url}
        height="100%"
        alt={image.alt ?? "Hero image"}
        imageProps={{ loading: "eager" }}
      />
      <Box position="absolute" inset={0} zIndex={1} bg="rgba(34, 29, 16, 0.5)" />
      <Container px={{ base: 4, md: 10, xl: 20 }} my={my} w="100%" zIndex={1}>
        {(slice.primary?.title ?? []).length > 0 && (
          <Box color="white" mb={slice.variation === "small" ? 0 : 6}>
            <PrismicRichText field={slice.primary?.title} components={htmlSerializer} />
          </Box>
        )}
        {(slice.primary?.description ?? []).length > 0 && (
          <SectionDescription color="white" mb={10} fontSize="lg">
            <PrismicRichText field={slice.primary?.description} components={htmlSerializer} />
          </SectionDescription>
        )}
        {slice.variation === "city" && (
          <Link
            href={
              slice.primary?.buttonLink.url
                ? slice.primary?.buttonLink.url
                : { pathname: `/[uid]/homes`, query: { uid: router.query.uid } }
            }
            passHref
            legacyBehavior
          >
            <Button
              as={ChakraLink}
              href={
                slice.primary?.buttonLink.url
                  ? slice.primary?.buttonLink.url
                  : { pathname: `/[uid]/homes`, query: { uid: router.query.uid } }
              }
              variant="tertiary"
              color="gray.900"
              textDecoration="none"
              onClick={onHomesButtonClick}
            >
              {slice.primary?.buttonLabel}
            </Button>
          </Link>
        )}
        {slice.variation === "default" && (
          <CitiesMenu variant="white" toApartments>
            {slice.primary?.buttonLabel ?? "Find your home"}
          </CitiesMenu>
        )}
      </Container>
    </Flex>
  );
};

HeroSection.displayName = HeroSection;

export default HeroSection;
