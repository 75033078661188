import { HorizontalScrollGallery } from "@/components/HorizontalScrollGallery";
import { Section, SectionDescription, SectionHeading } from "@/components/Section";
import { htmlSerializer } from "@/lib/htmlSerializer";
import { Box } from "@chakra-ui/react";
import { PrismicRichText } from "@prismicio/react";
import Image from "next/legacy/image";
import * as React from "react";
import yellowScribble from "../../../public/scribble2.png";
import orangeScribble from "../../../public/scribble3.png";

const SECTION_VARIANT = {
  greenWhite: "green",
  greenCream: "green",
  whiteCream: "white",
  creamWhite: "cream",
};

const GALLERY_BG = {
  greenWhite: "white",
  greenCream: "cream",
  whiteCream: "cream",
  creamWhite: "white",
};

const GallerySection = ({ slice }) => {
  const images = slice.items.length > 1 ? slice.items.map(({ image }) => image) : [];
  const variant = SECTION_VARIANT[slice.variation === "default" ? "greenWhite" : slice.variation];
  return (
    <Box>
      <Section variant={variant} pb={{ base: "154px", md: "264px", xl: "230px" }} position="relative">
        <SectionHeading>{slice.primary.title}</SectionHeading>
        <SectionDescription mb={0}>
          <PrismicRichText field={slice.primary.description} components={htmlSerializer} />
        </SectionDescription>
        <Box
          position="absolute"
          display={{ base: "none", lg: "block" }}
          top={{ lg: 140 }}
          right={{ lg: -234, xl: -134 }}
        >
          <Image src={variant === "green" ? yellowScribble : orangeScribble} alt="" width={560} height={520} />
        </Box>
      </Section>
      <Box
        mt={{ base: "-114px", md: "-184px", xl: "-150px" }}
        bg={GALLERY_BG[slice.variation === "default" ? "greenWhite" : slice.variation]}
      >
        {images.length > 0 && (
          <HorizontalScrollGallery
            gridTemplateColumns={{
              base: `0px repeat(${images.length}, 312px) 0px`,
              md: `24px repeat(${images.length}, 512px) 24px`,
              xl: `64px repeat(${images.length}, 416px ) 64px`,
            }}
            images={images}
          />
        )}
      </Box>
    </Box>
  );
};

GallerySection.displayName = GallerySection;

export default GallerySection;
