import { Card } from "@/components/common";
import { useProvidedStyles } from "@/hooks/useProvidedStyles";
import { htmlSerializer } from "@/lib/htmlSerializer";
import type { BoxProps } from "@chakra-ui/react";
import { Box, Heading, Image, Text } from "@chakra-ui/react";
import { PrismicRichText } from "@prismicio/react";
import * as React from "react";

interface StepCardProps extends BoxProps {
  step: {
    index: number;
    description: React.ComponentProps<typeof PrismicRichText>["field"];
    title: string;
    image?: {
      url: string;
    };
  };
}

export const StepCard = ({ step, ...rest }: StepCardProps) => {
  const styles = useProvidedStyles({ name: "card" });

  const { index, title, description, image } = step;

  return (
    <Box {...rest}>
      <Card sx={styles} h="80px" w="80px" p={0} display="flex" justifyContent="center" alignItems="center" mb={8}>
        {image ? (
          <Image
            src={image.url}
            w="48px"
            h="48px"
            alt=""
            // Filter to change svg colour. See: https://codepen.io/sosuke/pen/Pjoqqp
            filter="invert(73%) sepia(53%) saturate(6399%) hue-rotate(345deg) brightness(90%) contrast(78%)"
          />
        ) : (
          <Heading as="p" fontSize="48px" fontWeight="semibold" color="almond">
            {index}
          </Heading>
        )}
      </Card>
      <Text as="h6" fontSize="18px" fontWeight="medium" mb={4}>
        {title}
      </Text>
      <PrismicRichText field={description} components={htmlSerializer} />
    </Box>
  );
};
