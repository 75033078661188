import { CitiesDocument } from "@/generated/graphql";
import { useGraphqlQuery } from "@/lib/useGraphql";
import { GridProps } from "@chakra-ui/react";
import { Grid } from "@chakra-ui/react";
import { gql } from "graphql-tag";
import * as React from "react";
import { CityCard, CITY_CARD_FRAGMENT } from "../CityCard";

export const CITIES = gql`
  query Cities($filter: CityDefaultFilter) {
    cities(filter: $filter) {
      id
      ...CityCard
    }
    ${CITY_CARD_FRAGMENT}
  }
`;

type CitiesProps = GridProps;

export const Cities = (props: CitiesProps) => {
  const { data } = useGraphqlQuery(CitiesDocument, { filter: { unitPublished: true } });

  return (
    <Grid
      gridTemplateColumns={{ base: "repeat(2, 1fr)", md: "repeat(3, 1fr)", xl: "repeat(6, 1fr)" }}
      columnGap={4}
      rowGap={10}
      {...props}
    >
      {data?.cities.map((city) => (
        <CityCard key={city.id} city={city} imageSizes={{ base: '50vw', md: '25vw', xl: '15vw' }} />
      ))}
    </Grid>
  );
};
