import { Image } from "@/components/common";
import { FeatureList } from "@/components/FeatureList";
import { Section, SectionDescription, SectionHeading } from "@/components/Section";
import { htmlSerializer } from "@/lib/htmlSerializer";
import { Box } from "@chakra-ui/react";
import { PrismicRichText } from "@prismicio/react";
import * as React from "react";

const BaseApp = ({ imageUrl }) => (
  <Box
    display={{ base: "flex", md: "none" }}
    h="432px"
    overflowY="hidden"
    mt={20}
    justifyContent="center"
    mb="calc(-80px - 16px)"
    mx={-4}
  >
    <Box w="100%" maxW="343px" h="760px" position="relative" overflow="hidden">
      <Image src={imageUrl} alt="App" sizes="289px" imageProps={{ objectFit: "contain" }} />
    </Box>
  </Box>
);

const MdApp = ({ imageUrl }) => (
  <Box
    display={{ base: "none", md: "flex" }}
    position={{ md: "absolute" }}
    top={{ md: 0, xl: "140px" }}
    bottom={{ md: 0 }}
    margin={{ md: "auto", xl: "unset" }}
    right={{ md: "-48px", xl: "158px" }}
    width={{ md: "248px", xl: "338px" }}
    height={{ md: "550px", xl: "750px" }}
    overflow="hidden"
  >
    <Image src={imageUrl} alt="App" sizes="289px" imageProps={{ objectFit: "contain" }} />
  </Box>
);

const AppSection = ({ slice }) => {
  const { image, title, description, features } = slice.primary.app.data;

  return (
    <Section variant={slice.variation === "default" ? undefined : slice.variation} position="relative">
      <Box pr={{ md: "calc(248px - 48px + 1rem)", xl: "calc(338px + 158px + 1rem)" }}>
        <SectionHeading>{title}</SectionHeading>
        <SectionDescription mb={10}>
          <PrismicRichText field={description} components={htmlSerializer} />
        </SectionDescription>
      </Box>
      <FeatureList
        features={features.map(({ feature }) => {
          return { feature, included: true };
        })}
      />
      {image && <MdApp imageUrl={image.url} />}
      {image && <BaseApp imageUrl={image.url} />}
    </Section>
  );
};

AppSection.displayName = AppSection;

export default AppSection;
