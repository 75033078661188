import { Image } from "@/components/common/Image";
import { Section } from "@/components/Section";
import { Flex, Stack, Text } from "@chakra-ui/react";
import React from "react";

const LogoCloud = ({ slice }) => (
  <Section variant={slice.variation === "default" ? "green" : slice.variation} py={{ base: 20, md: "140px", xl: 16 }}>
    <Flex flexDirection={{ base: "column", xl: "row" }} alignItems="center">
      <Text minWidth="max-content" as="div" mb={{ base: 10, xl: 0 }} mr={{ base: 0, xl: 16 }}>
        {slice.primary.title}
      </Text>
      <Stack
        direction={{ base: "column", xl: "row" }}
        flex="1 1 0%"
        alignItems="center"
        justifyContent={{ xl: "space-between" }}
        spacing={12}
      >
        {slice.primary.logoCloud.data.logos.map(({ logo }) => {
          return (
            <Image
              key={logo.url}
              src={logo.url}
              alt={logo.alt}
              height={`${logo.dimensions.height}px`}
              width={`${logo.dimensions.width}px`}
              filter={
                slice.variation === "default" || slice.variation === "black"
                  ? "invert(97%) sepia(0%) saturate(7492%) hue-rotate(112deg) brightness(112%) contrast(99%)"
                  : undefined
              }
            />
          );
        })}
      </Stack>
    </Flex>
  </Section>
);

LogoCloud.displayName = LogoCloud;

export default LogoCloud;
