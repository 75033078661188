import { AvailableApartmentsPreviewDocument } from "@/generated/graphql";
import { useGraphqlQuery } from "@/lib/useGraphql";
import type { GridProps } from "@chakra-ui/react";
import { Box, Grid, useBreakpointValue } from "@chakra-ui/react";
import gql from "graphql-tag";
import * as React from "react";
import { ApartmentCard } from "../ApartmentCard";
import { APARTMENT_CARD_FRAGMENT } from "../ApartmentCard/ApartmentCard";
import { ApartmentCardLoading } from "../ApartmentCardLoading";
import { AvailableRoomsCard } from "../AvailableRoomsCard";

export const AVAILABLE_APARTMENTS = gql`
  query AvailableApartmentsPreview($citySlug: String!, $perPage: Int) {
    listings(citySlug: $citySlug, perPage: $perPage) {
      apartmentId
      ...ApartmentCard
    }
  }
  ${APARTMENT_CARD_FRAGMENT}
`;

interface AvailableApartmentsProps extends GridProps {
  citySlug: string;
  currentApartmentId?: number;
  includeCta?: boolean;
  numApartments?: number;
  withBadge?: boolean;
}

export const AvailableApartments = ({
  citySlug,
  currentApartmentId,
  includeCta = true,
  numApartments,
  withBadge = false,
  ...rest
}: AvailableApartmentsProps) => {
  const perPage = useBreakpointValue(
    {
      base: 5,
      sm: 5,
      md: 7,
      lg: 7,
      xl: 9,
      "2xl": 9,
    },
    "base",
  );

  const { data, isLoading } = useGraphqlQuery(AvailableApartmentsPreviewDocument, {
    citySlug,
    perPage: numApartments ? numApartments + 1 : perPage ?? 0,
  });

  const numCards = numApartments ? numApartments : perPage ? perPage - 1 : 0;

  return (
    <Box>
      <Grid
        gridTemplateColumns={{ base: "1fr", md: "repeat(2, minmax(0, 1fr))", xl: "repeat(4, minmax(0, 1fr))" }}
        columnGap={4}
        rowGap={{ base: 16, md: 14 }}
        mb={{ base: includeCta ? 16 : 0, md: includeCta ? 20 : 0 }}
        {...rest}
      >
        {isLoading
          ? Array.from({ length: numCards }, (_, i) => i + 1).map((i) => <ApartmentCardLoading key={i} />)
          : data?.listings
              .filter((listing) => {
                if (currentApartmentId) {
                  return listing.apartmentId !== currentApartmentId;
                }

                return true;
              })
              .slice(0, numCards)
              .map((listing) => (
                <ApartmentCard
                  key={listing.apartmentId}
                  apartment={listing}
                  imageSizes={{ base: "100vw", md: "50vw", xl: "25vw" }}
                  withBadge={withBadge}
                  minW={0}
                />
              ))}
      </Grid>
      {includeCta && <AvailableRoomsCard citySlug={citySlug} />}
    </Box>
  );
};
