import { Image } from "@/components/common";
import type { StackProps } from "@chakra-ui/react";
import { HStack, Text } from "@chakra-ui/react";
import * as React from "react";

export interface TextUspProps extends StackProps {
  image: {
    dimensions: { width: number; height: number };
    url: string;
  };
  title: string;
}

export const TextUsp = ({ image, title, ...rest }: TextUspProps) => {
  return (
    <HStack spacing={4} alignItems="center" {...rest}>
      <Image src={image.url} height="32px" width="32px" sizes="32px" alt="" />
      <Text as="h6" fontSize="1.25rem" fontWeight="medium">
        {title}
      </Text>
    </HStack>
  );
};
