import { Section, SectionDescription, SectionHeading } from "@/components/Section";
import { ValueCard } from "@/components/ValueCard";
import { htmlSerializer } from "@/lib/htmlSerializer";
import { Box, Grid } from "@chakra-ui/react";
import Image from "next/legacy/image";
import { PrismicRichText } from "@prismicio/react";
import * as React from "react";
import scribble from "../../../public/scribble4.png";

const ValuesSection = ({ slice }) => (
  <Section variant={slice.variation === "default" ? "white" : slice.variation} position="relative" overflow="hidden">
    <SectionHeading>{slice.primary.title}</SectionHeading>
    <SectionDescription>
      <PrismicRichText field={slice.primary.description} components={htmlSerializer} />
    </SectionDescription>
    <Grid gridTemplateColumns={{ base: "1fr", md: "1fr", xl: "repeat(2, 1fr)" }} columnGap={4} rowGap={4}>
      {slice?.items?.map((item) => (
        <ValueCard key={item.title} description={item.description} title={item.title} zIndex={1} />
      ))}
    </Grid>
    <Box position="absolute" display={{ base: "none", lg: "block" }} top={{ lg: 630 }} left={{ lg: -150 }}>
      <Image src={scribble} alt="" width={584} height={886} />
    </Box>
  </Section>
);

ValuesSection.displayName = ValuesSection;

export default ValuesSection;
