import { Section, SectionDescription, SectionHeading } from "@/components/Section";
import { Image } from "@/components/common";
import { htmlSerializer } from "@/lib/htmlSerializer";
import { Box, Button } from "@chakra-ui/react";
import { PrismicNextLink } from "@prismicio/next";
import { PrismicRichText } from "@prismicio/react";
import Link from "next/link";
import { useRouter } from "next/router";

const SignupCta = ({ slice }) => {
  const router = useRouter();
  const image = slice.primary?.image;

  return (
    <Section
      position="relative"
      isolation="isolate"
      variant={slice.variation === "default" ? undefined : slice.variation}
    >
      {image && <Image position="absolute" inset={0} zIndex={0} src={image.url} height="100%" alt="" />}
      <Box position="absolute" inset={0} zIndex={1} bg="rgba(34, 29, 16, 0.5)" />
      <SectionHeading zIndex={2} position="relative" color="white">
        {slice.primary.title}
      </SectionHeading>
      <SectionDescription zIndex={2} position="relative" color="white" mb={10}>
        <PrismicRichText field={slice.primary.description} components={htmlSerializer} />
      </SectionDescription>
      {slice.primary.buttonLink ? (
        <PrismicNextLink field={slice.primary.buttonLink}>
          <Button as="a" zIndex={2} variant="tertiary" color="gray.900">
            {slice.primary.buttonLabel}
          </Button>
        </PrismicNextLink>
      ) : (
        <Link href={{ pathname: "/[uid]/signup", query: { uid: router.query.uid } }} passHref legacyBehavior>
          <Button as="a" zIndex={2} variant="tertiary" color="gray.900">
            {slice.primary.buttonLabel}
          </Button>
        </Link>
      )}
    </Section>
  );
};

export default SignupCta;
