import { Section, SectionHeading } from "@/components/Section";
import { TextUsp } from "@/components/TextUsp";
import { Box, Grid } from "@chakra-ui/react";
import * as React from "react";
import scribble from "../../../public/scribble3.png";
import Image from "next/legacy/image";

const TextUspShortSection = ({ slice }) => (
  <Section variant={slice.variation === "default" ? undefined : slice.variation} position="relative" overflow="hidden">
    <Box
      transform="rotate(-135deg)"
      position="absolute"
      display={{ base: "none", md: "block" }}
      top={{ md: "-300px" }}
      right={{ md: "-300px", lg: "-40px" }}
    >
      <Image src={scribble} alt="" width={560} height={520} />
    </Box>
    <SectionHeading mb={{ base: 16, md: 20 }}>{slice.primary.title}</SectionHeading>
    <Grid gridTemplateColumns={{ base: "1fr", md: "1fr 1fr", xl: "repeat(3, 1fr)" }} gap={16}>
      {slice?.items?.map(({ usp }) => (
        <TextUsp key={usp.data.title} title={usp.data.title} image={usp.data.image} />
      ))}
    </Grid>
  </Section>
);

TextUspShortSection.displayName = TextUspShortSection;

export default TextUspShortSection;
