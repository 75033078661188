import { SectionHeading } from "@/components/Section";
import type { BoxProps } from "@chakra-ui/react";
import { Box, Text } from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";

const CALENDLY_URL: Record<string, string> = {
  aarhus: "https://calendly.com/joinlifex/introduction-call-with-lifex-aarhus",
  berlin: "https://calendly.com/joinlifex/introduction-call-with-lifex-berlin",
  copenhagen: "https://calendly.com/joinlifex/introduction-call-with-lifex-copenhagen",
  halle: "https://calendly.com/joinlifex/introduction-call-with-lifex-halle",
  london: "https://calendly.com/joinlifex/introduction-call-with-lifex-london",
  munich: "https://calendly.com/joinlifex/introduction-call-with-lifex-munich",
  paris: "https://calendly.com/joinlifex/introduction-call-with-lifex-paris",
  oslo: "https://calendly.com/joinlifex/introduction-call-with-lifex-oslo",
  hamburg: "https://calendly.com/joinlifex/introduction-call-with-lifex-hamburg",
};

interface CalendlyFormProps {
  prefill: Record<string, any>;
  citySlug: string;
}

export const CalendlyForm: React.FC<CalendlyFormProps> = ({ citySlug, prefill }) => {
  return (
    <Box mt={{ md: "-66px", lg: 0, xl: "-66px" }} h="min-content">
      <InlineWidget
        url={CALENDLY_URL[citySlug]}
        styles={{
          height: "1350px",
        }}
        prefill={prefill}
      />
    </Box>
  );
};

export interface CalendlyProps extends BoxProps {
  onEventScheduled: () => void;
  prefill: Record<string, any>;
  citySlug: string;
}

export const Calendly = ({ onEventScheduled, prefill, citySlug, ...rest }: CalendlyProps) => {
  const { t } = useTranslation("signup");

  useCalendlyEventListener({
    onEventScheduled,
  });

  return (
    <Box {...rest}>
      <SectionHeading as="h1" id="schedule-call">
        {t("schedule-a-call-title")}
      </SectionHeading>
      <Text mb={{ base: 10, xl: 20 }}>{t("schedule-a-call-description")}</Text>
      <CalendlyForm citySlug={citySlug} prefill={prefill} />
    </Box>
  );
};
