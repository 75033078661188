import { useBreakpointValue } from "@chakra-ui/react";

export interface Image {
  dimensions: {
    width: number;
    height: number;
  };
  alt: string;
  copyright?: any;
  url: string;
}

export interface PrismicImage {
  dimensions: {
    width: number;
    height: number;
  };
  alt: string;
  copyright?: any;
  url: string;
  base: Image;
  sm: Image;
  md: Image;
  lg: Image;
}

export const useBreakpointImage = () => {
  const breakpoint = useBreakpointValue(
    {
      base: "base",
      sm: "sm",
      md: "md",
      lg: "lg",
      xl: null,
      "2xl": null,
    },
    "base",
  );

  const breakpointImage = (prismicImage: PrismicImage): Image => {
    return breakpoint ? prismicImage[breakpoint as keyof PrismicImage] : prismicImage;
  };

  return breakpointImage;
};
