import * as React from "react";

const cachedScripts: string[] = [];
const src = "https://js.hsforms.net/forms/v2.js";

export function useHubspotFormScript(addToHead?: boolean): boolean[] {
  const [loaded, setLoaded] = React.useState(false);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    if (cachedScripts.includes(src)) {
      setLoaded(true);
      setError(false);
    } else {
      cachedScripts.push(src);

      const script = document.createElement("script");
      script.src = src;
      script.async = true;

      const onScriptLoad = () => {
        setLoaded(true);
        setError(false);
      };

      const onScriptError = () => {
        const index = cachedScripts.indexOf(src);

        if (index >= 0) {
          cachedScripts.splice(index, 1);
        }

        script.remove();
        setLoaded(true);
        setError(true);
      };

      script.addEventListener("load", onScriptLoad);
      script.addEventListener("error", onScriptError);

      // eslint-disable-next-line unicorn/prefer-dom-node-append
      addToHead ? document.head.appendChild(script) : document.body.appendChild(script);

      return () => {
        script.removeEventListener("load", onScriptLoad);
        script.removeEventListener("error", onScriptError);
      };
    }

    return;
  }, [addToHead]);

  return [loaded, error];
}
