import { Card } from "@/components/common";
import type { CardProps } from "@/components/common/Card";
import { useProvidedStyles } from "@/hooks/useProvidedStyles";
import { htmlSerializer } from "@/lib/htmlSerializer";
import { Button, Flex } from "@chakra-ui/react";
import { PrismicRichText } from "@prismicio/react";
import type { RichTextField } from "@prismicio/types";
import type { LinkProps } from "next/link";
import Link from "next/link";
import * as React from "react";

function isRichTextField(content: any): content is React.ComponentProps<typeof PrismicRichText>["field"] {
  if (content.length > 0) {
    return content[0].type;
  }

  return false;
}

export interface CTACardProps extends CardProps {
  buttonLabel: string;
  buttonLink: LinkProps["href"];
  content: RichTextField | React.ReactNode;
  buttonVariant?: "link" | "primary";
  onButtonClick?: () => void;
}

export const CTACard = ({ buttonLabel, buttonLink, buttonVariant, content, onButtonClick, ...rest }: CTACardProps) => {
  const styles = useProvidedStyles({ name: "card" });

  return (
    <Card
      sx={styles}
      display="flex"
      flexDirection={{ base: "column", md: "row" }}
      alignItems={{ base: "flex-start", md: "center" }}
      {...rest}
    >
      <Flex flex={1} mb={{ base: 4, md: 0 }}>
        {isRichTextField(content) ? <PrismicRichText field={content} components={htmlSerializer} /> : content}
      </Flex>
      <Link href={buttonLink} passHref legacyBehavior>
        <Button as="a" variant={buttonVariant} onClick={onButtonClick}>
          {buttonLabel}
        </Button>
      </Link>
    </Card>
  );
};
