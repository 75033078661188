import { Section, SectionDescription, SectionHeading } from "@/components/Section";
import { WhyCard } from "@/components/WhyCard";
import { htmlSerializer } from "@/lib/htmlSerializer";
import { Box, UnorderedList } from "@chakra-ui/react";
import { PrismicRichText } from "@prismicio/react";
import Image from "next/legacy/image";
import * as React from "react";
import scribble from "../../../public/scribble5.png";

const WhySection = ({ slice }) => (
  <Section variant={slice.variation === "default" ? "white" : slice.variation} position="relative" overflow="hidden">
    <SectionHeading>{slice.primary.title}</SectionHeading>
    <SectionDescription>
      <PrismicRichText field={slice.primary.description} components={htmlSerializer} />
    </SectionDescription>
    <UnorderedList
      display="grid"
      gridTemplateColumns={{ base: "1fr", md: "1fr", xl: "repeat(2, 1fr)" }}
      columnGap={4}
      rowGap={10}
    >
      {slice?.items?.map((item) => (
        <WhyCard key={item.title} title={item.title} description={item.description} zIndex={1} />
      ))}
    </UnorderedList>
    <Box position="absolute" display={{ base: "none", xl: "block" }} top={{ xl: 780 }} right={{ xl: -240 }}>
      <Image src={scribble} alt="" width={780} height={1000} />
    </Box>
  </Section>
);

WhySection.displayName = WhySection;

export default WhySection;
