import { ContactCard } from "@/components/ContactCard";
import { Section, SectionHeading } from "@/components/Section";
import { Grid } from "@chakra-ui/react";
import * as React from "react";

const ContactsSection = ({ slice }) => (
  <Section variant={slice.variation === "default" ? undefined : slice.variation}>
    <SectionHeading mb={{ base: 16, md: 20 }}>{slice.primary.title}</SectionHeading>
    <Grid
      gridTemplateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", xl: "repeat(3, 1fr)" }}
      columnGap={4}
      rowGap={16}
    >
      {slice?.items?.map((item, index) => (
        <ContactCard key={index} contact={item} />
      ))}
    </Grid>
  </Section>
);

ContactsSection.displayName = ContactsSection;

export default ContactsSection;
