import { ImageCard } from "@/components/ImageCard";
import { Section, SectionDescription, SectionHeading } from "@/components/Section";
import { USPsCard } from "@/components/USPsCard";
import { htmlSerializer } from "@/lib/htmlSerializer";
import { Box, Grid } from "@chakra-ui/react";
import Image from "next/legacy/image";
import { PrismicRichText } from "@prismicio/react";
import * as React from "react";
import scribble from "../../../public/scribble1.png";

const UspSection = ({ slice }) => {
  const isCenterVariant = slice.variation.toLowerCase().includes("center") ? true : false;
  const sectionVariant = slice.variation.toLowerCase().includes("cream") ? "cream" : "white";

  return (
    <Section variant={sectionVariant} position="relative" overflow="hidden">
      <Box
        position="absolute"
        display={{ base: "none", lg: isCenterVariant ? "none" : "block" }}
        top={{ lg: 0 }}
        right={{ lg: "-240px", xl: "-140px" }}
      >
        <Image src={scribble} alt="" width={440} height={340} />
      </Box>
      <SectionHeading
        maxW={isCenterVariant ? "100%" : "880px"}
        textAlign={isCenterVariant ? "center" : "start"}
        mb={{ base: isCenterVariant ? 16 : 6, md: isCenterVariant ? 20 : 6 }}
      >
        {slice.primary.title}
      </SectionHeading>
      {!isCenterVariant ? (
        <SectionDescription>
          <PrismicRichText field={slice.primary.description} components={htmlSerializer} />
        </SectionDescription>
      ) : null}
      <Grid gridTemplateColumns={{ base: "1fr", md: "1fr 1fr", xl: "repeat(4, 1fr)" }} gap={4}>
        {slice?.items?.map(({ usp }) => (
          <ImageCard
            key={usp.data.title}
            description={usp.data.description}
            title={usp.data.title}
            imageUrl={usp.data.image.url}
            imageSizes={{ base: "100vw", sm: "50vw", xl: "25vw" }}
          />
        ))}
      </Grid>
      {slice.primary.usps && slice.primary.additionalTitle && !isCenterVariant ? (
        <USPsCard mt={4} title={slice.primary.additionalTitle} usps={slice.primary.usps.split(",")} />
      ) : null}
    </Section>
  );
};

UspSection.displayName = UspSection;

export default UspSection;
