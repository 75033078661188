import type { BoxProps } from "@chakra-ui/react";
import { Box, FormControl, Skeleton } from "@chakra-ui/react";
import * as React from "react";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FormLoadingProps extends BoxProps {}

export const FormLoading = (props: BoxProps) => (
  <Box as="form" display="grid" gridTemplateColumns={{ base: "1fr", md: "1fr 1fr" }} gridGap={4} {...props}>
    <FormControl gridColumn={{ base: "span 2", md: "span 1" }}>
      <Skeleton h="40px" w="100%" />
    </FormControl>

    <FormControl gridColumn={{ base: "span 2", md: "span 1" }}>
      <Skeleton h="40px" w="100%" />
    </FormControl>

    <FormControl gridColumn={{ base: "span 2" }}>
      <Skeleton h="40px" w="100%" />
    </FormControl>

    <FormControl gridColumn={{ base: "span 2" }}>
      <Skeleton h="40px" w="100%" />
    </FormControl>

    <FormControl gridColumn={{ base: "span 2" }}>
      <Skeleton h="40px" w="100%" />
    </FormControl>

    <Box mt={10}>
      <Skeleton h="40px" w="80px" />
    </Box>
  </Box>
);
