import { Image } from "@/components/common/Image";
import { LargeTextTab } from "@/components/LargeTextTab";
import { Section, SectionDescription, SectionHeading } from "@/components/Section";
import { htmlSerializer } from "@/lib/htmlSerializer";
import { Center, Fade, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import * as prismicH from "@prismicio/helpers";
import { PrismicRichText } from "@prismicio/react";
import * as React from "react";

const TabbedImagesSection = ({ slice }) => {
  const sectionVariant = slice.variation.toLowerCase().includes("cream") ? "cream" : "white";
  const [tabIndex, setTabIndex] = React.useState(0);

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  return (
    <Section variant={sectionVariant} maxH={{ base: "100%", lg: "1056px" }} overflow="hidden">
      <SectionHeading>{slice.primary.title}</SectionHeading>
      <SectionDescription>
        <PrismicRichText field={slice.primary.description} components={htmlSerializer} />
      </SectionDescription>
      <Tabs
        orientation="vertical"
        display="grid"
        gridTemplateColumns={{ base: "1fr", lg: "repeat(12, 1fr)" }}
        gridColumnGap={4}
        gridRowGap={10}
        index={tabIndex}
        onChange={handleTabsChange}
        isLazy
      >
        <TabList gridColumn={{ base: "1 / -1", lg: "1 / span 5" }}>
          {slice.items.map((item, index) => (
            <LargeTextTab
              key={`tab-${index}`}
              title={item.title}
              description={prismicH.asText(item.description)}
              __css={{
                "&:not(:last-of-type)": { mb: 2 },
                "& > div.selected": { bg: sectionVariant === "white" ? "cream" : "white" },
                "& > div:hover": { bg: sectionVariant === "white" ? "cream" : "white", transition: "background 0.2s" },
              }}
            />
          ))}
        </TabList>
        <TabPanels gridColumn={{ base: "1 / -1", lg: "7 / -1" }}>
          {slice.items.map((item, index) => (
            <TabPanel key={`tab-panel-${index}`} px={10}>
              <Center maxH="100%" overflow="hidden">
                <Fade in={tabIndex === index}>
                  <Image src={item.image.url} alt={item.image.alt} flexShrink={0} maxW="420px" />
                </Fade>
              </Center>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Section>
  );
};

TabbedImagesSection.displayName = TabbedImagesSection;

export default TabbedImagesSection;
