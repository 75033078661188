import { Card } from "@/components/common";
import { Image } from "@/components/common/Image";
import { Section, SectionDescription } from "@/components/Section";
import { useProvidedStyles } from "@/hooks/useProvidedStyles";
import { htmlSerializer } from "@/lib/htmlSerializer";
import { getHeadingId } from "@/utils/getHeadingId";
import { Button, chakra, Flex, Heading } from "@chakra-ui/react";
import * as prismicH from "@prismicio/helpers";
import { PrismicRichText } from "@prismicio/react";
import NextLink from "next/link";
import * as React from "react";

export const CTACard = ({ title, body, buttonLabel, buttonLink, ...rest }) => {
  const styles = useProvidedStyles({ name: "card" });
  const id = title ? getHeadingId(title) : null;

  return (
    <Card sx={styles} {...rest}>
      {title ? (
        <Heading as="h4" id={id} mb={6} fontSize="3xl" fontWeight="500">
          <chakra.a aria-hidden="true" tabIndex={-1} href={`#${id}`}>
            {title}
          </chakra.a>
        </Heading>
      ) : null}
      <PrismicRichText field={body} components={htmlSerializer} />
      {buttonLabel && buttonLink ? (
        <NextLink href={buttonLink.url} passHref legacyBehavior>
          <Button as="a" colorScheme="primary" mt={body.length > 0 ? 10 : 0}>
            {buttonLabel}
          </Button>
        </NextLink>
      ) : null}
    </Card>
  );
};

const GuideImageSection = ({ slice }) => {
  const sectionVariant = slice.variation.toLowerCase().includes("cream") ? "cream" : "white";
  const image = slice.primary.image;

  return (
    <Section
      variant={sectionVariant}
      containerProps={{
        display: { base: "flex", xl: "grid" },
        gridTemplateColumns: { xl: "1fr 416px" },
        flexDir: { base: "column-reverse", xl: "row" },
      }}
      pt={0}
      mt={20}
      mb={20}
      pb={0}
    >
      <Flex gap={20} flexDirection="column" mr={{ base: 0, xl: "124px" }} mt={{ base: 20, xl: 0 }}>
        <SectionDescription mb={0}>
          <PrismicRichText field={slice.primary.body} components={htmlSerializer} />
        </SectionDescription>
        {slice.primary.ctaTitle?.length > 0 || prismicH.asText(slice.primary.ctaBody).length > 0 ? (
          <CTACard
            title={slice.primary.ctaTitle}
            body={slice.primary.ctaBody}
            buttonLabel={slice.primary.ctaButtonLabel}
            buttonLink={slice.primary.ctaButtonLink}
          />
        ) : null}
      </Flex>
      {image?.url ? (
        <Image
          src={image.url}
          alt={image.alt}
          height={{ base: "344px", md: "688px", xl: "416px" }}
          width={{ base: "100%" }}
          sizes={{ base: "100vw", md: "688px", xl: "416px" }}
          flexShrink={0}
          borderRadius="lg"
          overflow="hidden"
          maxW="100%"
        />
      ) : null}
    </Section>
  );
};

GuideImageSection.displayName = GuideImageSection;

export default GuideImageSection;
