import { useProvidedStyles } from "@/hooks/useProvidedStyles";
import { htmlSerializer } from "@/lib/htmlSerializer";
import { Text } from "@chakra-ui/react";
import { PrismicRichText } from "@prismicio/react";
import * as React from "react";
import { Card } from "../common";
import type { CardProps } from "../common/Card";

interface ValueCardProps {
  title: string;
  description: React.ComponentProps<typeof PrismicRichText>["field"];
}

export const ValueCard = ({ title, description, ...rest }: ValueCardProps & CardProps) => {
  const styles = useProvidedStyles({ name: "card" });

  return (
    <Card sx={styles} {...rest}>
      <Text as="h4" mb={4} fontSize="lg" fontWeight="500">
        {title}
      </Text>
      <PrismicRichText field={description} components={htmlSerializer} />
    </Card>
  );
};
