import { Card, CardProps } from "@/components/common/Card";
import { FeatureList } from "@/components/FeatureList";
import { useProvidedStyles } from "@/hooks/useProvidedStyles";
import { htmlSerializer } from "@/lib/htmlSerializer";
import { Box, Text } from "@chakra-ui/react";
import { PrismicRichText } from "@prismicio/react";
import * as React from "react";

interface PricingCardProps extends CardProps {
  pricingTier: {
    title: string;
    description: React.ComponentProps<typeof PrismicRichText>["field"];
    features: { included: boolean; feature: string }[];
  };
}

export const PricingCard = ({ pricingTier, ...rest }: PricingCardProps) => {
  const styles = useProvidedStyles({ name: "card" });

  return (
    <Card sx={styles} {...rest}>
      <Text as="h5" mb={4} fontSize="24px" fontWeight="medium">
        {pricingTier.title}
      </Text>
      <Box mb={10}>
        <PrismicRichText field={pricingTier.description} components={htmlSerializer} />
      </Box>
      <FeatureList features={pricingTier.features} />
    </Card>
  );
};
