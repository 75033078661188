import { htmlSerializer } from "@/lib/htmlSerializer";
import type { BoxProps } from "@chakra-ui/react";
import { Box, Heading, Text } from "@chakra-ui/react";
import * as prismicH from "@prismicio/helpers";
import { PrismicRichText } from "@prismicio/react";
import * as React from "react";

interface AchievementCardProps extends BoxProps {
  achievement: { description: React.ComponentProps<typeof PrismicRichText>["field"]; title: string; value: string };
  variant?: string;
}

export const AchievementCard = ({ achievement, variant, ...rest }: AchievementCardProps) => {
  const linkColor = variant === "green" || variant === "black" ? "white" : "almonod";

  const hasDescription = (achievement.description?.length ?? 0) > 0 && prismicH.asText(achievement.description) !== "";

  return (
    <Box __css={{ a: { color: linkColor } }} {...rest}>
      <Heading as="p" fontSize={{ base: "40px", md: "48px" }} fontWeight="semibold" mb={2}>
        {achievement.value}
      </Heading>
      <Text fontSize="sm" fontWeight="medium" color="gray.500">
        {achievement.title}
      </Text>
      {hasDescription ? (
        <Box mt={8}>
          <PrismicRichText field={achievement.description} components={htmlSerializer} />
        </Box>
      ) : null}
    </Box>
  );
};
