import { HubSpotFormWithCalendly } from "@/components/HubSpotFormWithCalendly";
import { Section } from "@/components/Section";
import * as React from "react";

const FormWithCalendly = ({ slice }) => (
  <Section variant={slice.variation === "default" ? "white" : slice.variation} position="relative" overflow="hidden">
    <HubSpotFormWithCalendly
      citySlug={slice.primary.citySlug}
      title={slice.primary.title}
      description={slice.primary.description}
      formId={slice.primary.formId}
      portalId={slice.primary.portalId}
    />
  </Section>
);

FormWithCalendly.displayName = FormWithCalendly;

export default FormWithCalendly;
