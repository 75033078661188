import { Link } from "@/components/common";
import { FadeInImage } from "@/components/FadeInImage";
import { CityCardFragment } from "@/generated/graphql";
import { DEFAULT_CONTENTFUL_IMAGES_QUALITY } from "@/utils/assetsToImages";
import { Box, Flex, Text } from "@chakra-ui/react";
import { gql } from "graphql-tag";
import useTranslation from "next-translate/useTranslation";

export const CITY_CARD_FRAGMENT = gql`
  fragment CityCard on City {
    slug
    name
    cmsData
  }
`;

interface CityCardProps {
  city: CityCardFragment;
  imageSizes?: string | { [key: string]: string };
}

export const CityCard = ({ city, imageSizes }: CityCardProps) => {
  const { t } = useTranslation("common");

  return (
    <Link
      href={{ pathname: "/[uid]/homes", query: { uid: city.slug } }}
      _focus={{ boxShadow: "outline", borderRadius: "lg" }}
      _hover={{ textDecoration: "none" }}
    >
      <Flex display="flex" flexDirection="column" alignItems="center" w="100%" h="100%">
        <Box
          w="100%"
          minH={{ base: "246px", md: "328px", xl: "300px" }}
          h="100%"
          position="relative"
          borderRadius="lg"
          overflow="hidden"
          mb={4}
        >
          <FadeInImage
            position="absolute"
            inset={0}
            zIndex={0}
            sizes={imageSizes}
            src={`https:${city.cmsData?.image.file?.url}${DEFAULT_CONTENTFUL_IMAGES_QUALITY}`}
            alt={t(city.slug)}
          />
          <Box position="absolute" inset={0} zIndex={1} bg="blackAlpha.100" />
        </Box>
        <Text fontSize="lg" fontWeight="medium">
          {t(city.slug)}
        </Text>
      </Flex>
    </Link>
  );
};
