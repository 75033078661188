import { Section, SectionHeading } from "@/components/Section";
import { TestimonialCard } from "@/components/TestimonialCard";
import { TrustBox } from "@/components/TrustBox";
import { Grid } from "@chakra-ui/react";
import * as React from "react";

const TestimonialsSection = ({ slice }) => (
  <Section variant={slice.variation === "default" ? undefined : slice.variation}>
    <Grid gridTemplateColumns={{ base: "1fr", xl: "1fr 1fr" }} mb={{ base: 8, xl: 20 }} gridGap={6} alignItems="center">
      <SectionHeading mb={0}>{slice.primary.title}</SectionHeading>
      <TrustBox />
    </Grid>
    <Grid gridTemplateColumns={{ base: "1fr", xl: "repeat(2, 1fr)" }} gap={4}>
      {slice?.items?.map(({ testimonial }, i) => (
        <TestimonialCard key={i} testimonial={testimonial.data} />
      ))}
    </Grid>
  </Section>
);

TestimonialsSection.displayName = TestimonialsSection;

export default TestimonialsSection;
