import { AchievementCard } from "@/components/AchievementCard";
import { Section } from "@/components/Section";
import { Grid } from "@chakra-ui/react";
import React from "react";

const AchievementsSection = ({ slice }) => (
  <Section variant={slice.variation === "default" ? "green" : slice.variation} py={{ base: 16, xl: 20 }}>
    <Grid
      gridTemplateColumns={{ base: "1fr", md: "repeat(2, 1fr)", xl: "repeat(4, 1fr)" }}
      columnGap="124px"
      rowGap={16}
    >
      {slice.items.map((achievement, index) => (
        <AchievementCard
          key={index}
          achievement={achievement}
          variant={slice.variation === "default" ? "green" : slice.variation}
        />
      ))}
    </Grid>
  </Section>
);

AchievementsSection.displayName = AchievementsSection;

export default AchievementsSection;
