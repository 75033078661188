import { Image } from "@/components/common/Image";
import { Section, SectionDescription, SectionHeading } from "@/components/Section";
import { htmlSerializer } from "@/lib/htmlSerializer";
import { Flex, Text } from "@chakra-ui/react";
import { PrismicRichText } from "@prismicio/react";
import * as React from "react";

const ImageSection = ({ slice }) => {
  const imagePosition = slice.variation.includes("left") ? "left" : "right";
  const sectionVariant = slice.variation.toLowerCase().includes("cream") ? "cream" : "white";
  const image = slice.primary.image;

  const ImageComponent = (props) => (
    <Image
      src={image.url}
      alt={image.alt}
      height={{ base: "262px", md: "526px", lg: "400px" }}
      width={{ base: "100%", lg: "524px" }}
      sizes={{ base: "100vw", lg: "524px" }}
      flexShrink={0}
      borderRadius="lg"
      overflow="hidden"
      maxW="100%"
      borderWidth={1}
      borderStyle="solid"
      borderColor="gray.200"
      {...props}
    />
  );

  return (
    <Section variant={sectionVariant}>
      <Flex flexDirection={{ base: "column", xl: "row" }}>
        <ImageComponent display={{ base: "flex", xl: imagePosition === "left" ? "flex" : "none" }} />
        <Flex
          flexDirection="column"
          justifyContent="center"
          ml={{ base: 0, xl: imagePosition === "left" ? 32 : 0 }}
          mr={{ base: 0, xl: imagePosition === "right" ? 32 : 0 }}
          mt={{ base: 16, xl: 0 }}
        >
          {slice.primary.tagline ? (
            <Text color="almond" fontWeight="medium" mb={4}>
              {slice.primary.tagline}
            </Text>
          ) : null}
          <SectionHeading>{slice.primary.title}</SectionHeading>
          <SectionDescription mb={0}>
            <PrismicRichText field={slice.primary.description} components={htmlSerializer} />
          </SectionDescription>
        </Flex>
        <ImageComponent display={{ base: "none", xl: imagePosition === "right" ? "flex" : "none" }} />
      </Flex>
    </Section>
  );
};

ImageSection.displayName = ImageSection;

export default ImageSection;
