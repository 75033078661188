import { Image } from "@/components/common/Image";
import { Section, SectionDescription, SectionHeading } from "@/components/Section";
import { htmlSerializer } from "@/lib/htmlSerializer";
import { Box } from "@chakra-ui/react";
import { PrismicRichText } from "@prismicio/react";
import * as React from "react";

const SaasSection = ({ slice }) => {
  const sectionVariant = slice.variation.toLowerCase().includes("cream") ? "cream" : "white";

  const image = slice.primary.image;

  return (
    <Section
      variant={sectionVariant}
      pb={{ base: 24, md: 0 }}
      containerProps={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <SectionHeading textAlign="center">{slice.primary.title}</SectionHeading>
      <SectionDescription textAlign="center" mb={{ base: 0, md: "116px" }}>
        <PrismicRichText field={slice.primary.description} components={htmlSerializer} />
      </SectionDescription>
      <Box
        position="relative"
        height={{ base: "0px", md: "328px", lg: "408px" }}
        borderBottomRadius={0}
        width="100%"
        overflow="hidden"
        display={{ base: "none", md: "block" }}
      >
        <Image src={image.url} alt={image.alt} flexShrink={0} maxW="100%" />
      </Box>
    </Section>
  );
};

export default SaasSection;
