import { Image } from "@/components/common";
import type { BoxProps } from "@chakra-ui/react";
import { Box, Text } from "@chakra-ui/react";
import * as React from "react";

export interface WhatWeManageCardProps extends BoxProps {
  image: {
    dimensions: { width: number; height: number };
    url: string;
  };
  title: string;
  subtitle: string;
  description: string;
}

export const WhatWeManageCard = ({ description, image, subtitle, title, ...rest }: WhatWeManageCardProps) => {
  return (
    <Box {...rest}>
      <Image
        src={image.url}
        h={{ base: "192px", md: "188px", lg: "234px" }}
        alt=""
        mb={4}
        borderRadius="lg"
        overflow="hidden"
      />
      {title ? (
        <Text fontSize="1.25rem" fontWeight="medium" mb={2}>
          {title}
        </Text>
      ) : null}
      {subtitle ? <Text mb={2}>{subtitle}</Text> : null}
      {description ? (
        <Text fontSize="sm" color="gray.600">
          {description}
        </Text>
      ) : null}
    </Box>
  );
};
