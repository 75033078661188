import { Section, SectionDescription, SectionHeading } from "@/components/Section";
import { WhatWeManageCard } from "@/components/WhatWeManageCard";
import { htmlSerializer } from "@/lib/htmlSerializer";
import { Grid } from "@chakra-ui/react";
import { PrismicRichText } from "@prismicio/react";
import * as React from "react";

const WhatWeManageSection = ({ slice }) => {
  return (
    <Section variant={slice.variation === "default" ? "white" : slice.variation} position="relative" overflow="hidden">
      <SectionHeading>{slice.primary.title}</SectionHeading>
      <SectionDescription>
        <PrismicRichText field={slice.primary.description} components={htmlSerializer} />
      </SectionDescription>
      <Grid
        gridTemplateColumns={{ base: "1fr", md: "1fr 1fr", xl: "repeat(3, 1fr)" }}
        gridColumnGap={4}
        gridRowGap={10}
      >
        {slice?.items?.map((item) => (
          <WhatWeManageCard
            key={item.title}
            description={item.description}
            title={item.title}
            subtitle={item.subtitle}
            image={item.image}
          />
        ))}
      </Grid>
    </Section>
  );
};

WhatWeManageSection.displayName = WhatWeManageSection;

export default WhatWeManageSection;
