import { Box, LinkBox, LinkOverlay, Text } from "@chakra-ui/react";
import NextLink from "next/link";
import { BlogPageDocument } from "prismicio-types";
import { Image } from "../common";

export type BlogPage = {
  tags: BlogPageDocument<string>["tags"];
  data: Omit<BlogPageDocument<string>["data"], "slices">;
  uid: BlogPageDocument<string>["uid"];
};
export interface BlogCardProps {
  blogPage: BlogPage;
}

export function BlogCard({ blogPage }: BlogCardProps) {
  const { data, tags, uid } = blogPage;
  const height = { base: "230px", md: "224px", xl: "204px" };
  const width = "100%";

  const image = data.socialCards[0]?.image;

  return (
    <LinkBox position="relative" display="flex" flexDirection="column" as="article">
      {image ? (
        <Image src={image.url} height={height} width={width} alt="Blog image" borderRadius="lg" overflow="hidden" />
      ) : null}
      <Box mt={4}>
        <Text fontSize="12px" fontWeight="medium" color="almond" mb={1} textTransform="uppercase">
          {tags.join(", ")}
        </Text>
        <NextLink href={`/blog/${uid}`} passHref legacyBehavior>
          <LinkOverlay>
            <Text fontSize="xl" fontWeight="medium">
              {data.summary}
            </Text>
          </LinkOverlay>
        </NextLink>
      </Box>
    </LinkBox>
  );
}
