import { BlogCard } from "@/components/BlogCard";
import { Section, SectionDescription, SectionHeading } from "@/components/Section";
import { htmlSerializer } from "@/lib/htmlSerializer";
import { Button, Grid, Flex } from "@chakra-ui/react";
import { PrismicRichText } from "@prismicio/react";
import NextLink from "next/link";

const BlogSection = ({ slice }) => {
  const hasSubtitle = Boolean(slice.primary.subtitle);
  const hasCta = Boolean(slice.primary.ctaLink);
  const ctaUrl = slice.primary?.ctaLink?.url;

  return (
    <Section variant={slice.variation === "default" ? undefined : slice.variation}>
      <SectionHeading>{slice.primary.title}</SectionHeading>
      {hasSubtitle || hasCta ? (
        <Flex
          flexDirection={{ base: "column", md: "row" }}
          gap={4}
          justifyContent="space-between"
          mb={{ base: 16, lg: 20 }}
        >
          {hasSubtitle ? (
            <SectionDescription mb={0}>
              <PrismicRichText field={slice.primary.subtitle} components={htmlSerializer} />
            </SectionDescription>
          ) : (
            <div />
          )}
          {hasCta ? (
            <NextLink
              href={ctaUrl ? slice.primary.ctaLink.url.replace("https://www.joinlifex.com", "") : slice.primary.ctaLink}
              legacyBehavior
              passHref
            >
              <Button variant="secondary" as="a" colorScheme="primary">
                {slice.primary.ctaText ?? "Read more"}
              </Button>
            </NextLink>
          ) : null}
        </Flex>
      ) : null}
      <Grid
        gridTemplateColumns={{ base: "1fr", md: "1fr 1fr", xl: "repeat(3, 1fr)" }}
        columnGap={10}
        rowGap={{ base: 16, md: 14 }}
      >
        {slice.items.map(({ blogPage }) => {
          return <BlogCard key={blogPage.uid} blogPage={blogPage} />;
        })}
      </Grid>
    </Section>
  );
};

BlogSection.displayName = BlogSection;

export default BlogSection;
