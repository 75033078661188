import type { ListProps } from "@chakra-ui/react";
import { List, ListIcon, ListItem } from "@chakra-ui/react";
import * as React from "react";
import { RiCheckFill, RiCloseFill } from "react-icons/ri";

interface FeatureListProps extends ListProps {
  features: { feature: string; included: boolean }[];
}

export const FeatureList = ({ features, ...rest }: FeatureListProps) => (
  <List spacing={4} {...rest}>
    {features.map((item, index) => (
      <ListItem key={index} display="flex" alignItems="center" color={item.included ? "gray.900" : "gray.500"}>
        {item.included ? (
          <ListIcon as={RiCheckFill} fontSize="24px" color="green.600" />
        ) : (
          <ListIcon as={RiCloseFill} fontSize="24px" color="red.600" />
        )}
        {item.feature}
      </ListItem>
    ))}
  </List>
);
