import { CityMap } from "@/components/CityMap";
import { Section, SectionDescription, SectionHeading } from "@/components/Section";
import { htmlSerializer } from "@/lib/htmlSerializer";
import { Box, Flex } from "@chakra-ui/react";
import { PrismicRichText } from "@prismicio/react";
import * as React from "react";

const CityMapSection = ({ slice }) => {
  if (slice.variation === "full") {
    return <CityMap h="600px" w="100%" />;
  }

  const mapPosition = slice.variation.includes("left") ? "left" : "right";
  const sectionVariant = slice.variation.toLowerCase().includes("cream") ? "cream" : "white";

  return (
    <Section variant={sectionVariant}>
      <Flex flexDirection={{ base: "column", xl: "row" }}>
        <CityMap display={{ base: "none", xl: mapPosition === "left" ? "flex" : "none" }} flexShrink={0} />
        <Box
          ml={{ base: 0, xl: mapPosition === "left" ? 32 : 0 }}
          mr={{ base: 0, xl: mapPosition === "right" ? 32 : 0 }}
          mb={{ base: 16, xl: 0 }}
        >
          <SectionHeading>{slice.primary.title}</SectionHeading>
          <SectionDescription mb={0}>
            <PrismicRichText field={slice.primary.description} components={htmlSerializer} />
          </SectionDescription>
        </Box>
        <CityMap display={{ base: "flex", xl: mapPosition === "right" ? "flex" : "none" }} flexShrink={0} />
      </Flex>
    </Section>
  );
};

CityMapSection.displayName = CityMapSection;

export default CityMapSection;
