import { SectionHeading } from "@/components/Section";
import type { BoxProps } from "@chakra-ui/react";
import { Box, Button, Text } from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";
import NextLink from "next/link";

const GUIDE_HREF: Record<string, string> = {
  berlin: "/berlin/moving-to-berlin-germany",
  copenhagen: "/copenhagen/moving-to-copenhagen-denmark",
  london: "/london/moving-to-london-uk",
  munich: "/munich/moving-to-munich-germany",
  paris: "/paris/moving-to-paris-france",
};

export interface GuideStepProps extends BoxProps {
  cityName: string;
  citySlug: string;
}

export const GuideStep = ({ cityName, citySlug, ...rest }: GuideStepProps) => {
  const { t } = useTranslation("signup");

  return (
    <Box {...rest}>
      <SectionHeading as="h1" id="received-application">
        {t("guide-step.title")}
      </SectionHeading>
      {GUIDE_HREF[citySlug] ? (
        <>
          <Text mb={8}>{t("guide-step.body", { cityName })}</Text>
          <NextLink href={GUIDE_HREF[citySlug]} passHref legacyBehavior>
            <Button as="a" colorScheme="primary">
              {t("guide-step.cta")}
            </Button>
          </NextLink>
        </>
      ) : null}
    </Box>
  );
};
