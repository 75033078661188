import { FormLoading } from "@/components/FormLoading";
import { HubSpotFormContainer } from "@/components/HubSpotFormContainer";
import { SectionDescription, SectionHeading } from "@/components/Section";
import { useHubspotForm } from "@/hooks/useHubspotForm";
import { useHubspotTracking } from "@/hooks/useHubspotTracking";
import { AMPLITUDE_EVENTS, logEvent } from "@/lib/amplitude";
import { htmlSerializer } from "@/lib/htmlSerializer";
import { capitalise } from "@/utils/capitalise";
import type { BoxProps } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";
import { PrismicRichText } from "@prismicio/react";
import Script from "next/script";
import * as React from "react";
import { Calendly } from "../Calendly";
import { GuideStep } from "../GuideStep";

export interface HubSpotFormWithCalendlyProps extends BoxProps {
  citySlug: string;
  title: string;
  description: React.ComponentProps<typeof PrismicRichText>["field"];
  formId: string;
  portalId?: string;
}

export const HubSpotFormWithCalendly = ({
  citySlug,
  title,
  description,
  formId,
  portalId,
  ...rest
}: HubSpotFormWithCalendlyProps) => {
  const [currentStep, setCurrentStep] = React.useState(1);
  const [signupData, setSignupData] = React.useState<Record<string, any> | null>(null);
  const { setIdentity } = useHubspotTracking();
  const cityName = capitalise(citySlug);

  const id = `refer-a-friend-${citySlug}`;

  const { loaded } = useHubspotForm({
    portalId: portalId ?? "5105035",
    formId: formId,
    target: `#${id}`,
    onFormReady: ($form) => {
      $form.find('input[name="preferred_room"]').val(cityName).change();
    },
    onFormSubmit: ($form) => {
      const formData = $form
        .serializeArray()
        .reduce(function (accumulator: Record<string, any>, currentValue: Record<string, any>) {
          accumulator[currentValue.name] = currentValue.value;
          return accumulator;
        }, {});

      if (formData.email) {
        setIdentity(formData.email);
      }

      logEvent(AMPLITUDE_EVENTS.formSubmitted, { cityName: cityName, ...formData });

      setSignupData(formData);
    },
    onFormSubmitted: () => {
      setCurrentStep(2);
    },
  });

  const onEventScheduled = () => {
    setCurrentStep(3);
  };

  return (
    <Box {...rest}>
      <Script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js" id="jquery" />
      {!loaded && <FormLoading />}
      {currentStep === 1 && (
        <>
          <SectionHeading>{title}</SectionHeading>
          <SectionDescription>
            <PrismicRichText field={description} components={htmlSerializer} />
          </SectionDescription>
          <HubSpotFormContainer id={id} />
        </>
      )}
      {currentStep === 2 && (
        <Calendly
          citySlug={citySlug}
          onEventScheduled={onEventScheduled}
          prefill={{
            email: signupData?.email,
            name: `${signupData?.firstname ?? ""} ${signupData?.lastname ?? ""}`,
            customAnswers: {
              a1: `${signupData?.phone ?? ""}`,
            },
          }}
        />
      )}
      {currentStep === 3 && <GuideStep cityName={cityName} citySlug={citySlug} />}
    </Box>
  );
};
