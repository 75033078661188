import { AvailableRoomsCard } from "@/components/AvailableRoomsCard";
import { Section, SectionHeading } from "@/components/Section";
import { StepCard } from "@/components/StepCard";
import { Grid } from "@chakra-ui/react";
import { useRouter } from "next/router";
import * as React from "react";

const StepsSection = ({ slice }) => {
  const router = useRouter();

  return (
    <Section variant={slice.variation === "default" ? undefined : slice.variation}>
      <SectionHeading mb={{ base: 16, md: 20 }}>{slice.primary.title}</SectionHeading>
      <Grid
        gridTemplateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", xl: "repeat(3, 1fr)" }}
        columnGap={{ base: "64px", xl: "124px" }}
        rowGap={16}
        mb={{ base: slice.primary.includeCta ? 16 : 0, md: slice.primary.includeCta ? 20 : 0 }}
      >
        {slice?.items?.map(({ step }, index) => (
          <StepCard
            key={index}
            step={{
              index: index + 1,
              description: step.data.description,
              title: step.data.title,
              image: step.data.image,
            }}
          />
        ))}
      </Grid>
      {slice.primary.includeCta ? <AvailableRoomsCard citySlug={router.query.uid} /> : null}
    </Section>
  );
};

StepsSection.displayName = StepsSection;

export default StepsSection;
