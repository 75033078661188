// Code generated by Slice Machine. DO NOT EDIT.

import dynamic from "next/dynamic";
import AchievementsSection from "./AchievementsSection";
import ApartmentMapSection from "./ApartmentMapSection";
import ApartmentsSection from "./ApartmentsSection";
import AppSection from "./AppSection";
import AwardSection from "./AwardSection";
import BlogSection from "./BlogSection";
import CitiesSection from "./CitiesSection";
import CityMapSection from "./CityMapSection";
import CompanyUspSection from "./CompanyUspSection";
import ContactsSection from "./ContactsSection";
import CtaSection from "./CtaSection";
import EmbedSection from "./EmbedSection";
import FaqSection from "./FaqSection";
import FormSection from "./FormSection";
import FormWithCalendly from "./FormWithCalendly";
import GallerySection from "./GallerySection";
import GuideImageSection from "./GuideImageSection";
import GuideLocationSection from "./GuideLocationSection";
import GuideRatingSection from "./GuideRatingSection";
import HeroSection from "./HeroSection";
import ImageSection from "./ImageSection";
import LogoCloud from "./LogoCloud";
import PricingSection from "./PricingSection";
import SaasSection from "./SaasSection";
import SignupCta from "./SignupCta";
import SplitImageSection from "./SplitImageSection";
import StepsSection from "./StepsSection";
import TabbedImagesSection from "./TabbedImagesSection";
import TestimonialsSection from "./TestimonialsSection";
import TextSection from "./TextSection";
import TextUspSection from "./TextUspSection";
import TextUspShortSection from "./TextUspShortSection";
import TocSection from "./TocSection";
import UspSection from "./UspSection";
import ValuesSection from "./ValuesSection";
import WhatWeManageSection from "./WhatWeManageSection";
import WhySection from "./WhySection";

export {
  AchievementsSection,
  ApartmentMapSection,
  ApartmentsSection,
  AppSection,
  AwardSection,
  BlogSection,
  CitiesSection,
  CityMapSection,
  CompanyUspSection,
  ContactsSection,
  CtaSection,
  EmbedSection,
  FaqSection,
  FormSection,
  FormWithCalendly,
  GallerySection,
  GuideImageSection,
  GuideLocationSection,
  GuideRatingSection,
  HeroSection,
  ImageSection,
  LogoCloud,
  PricingSection,
  SaasSection,
  SignupCta,
  SplitImageSection,
  StepsSection,
  TabbedImagesSection,
  TestimonialsSection,
  TextSection,
  TextUspSection,
  TextUspShortSection,
  TocSection,
  UspSection,
  ValuesSection,
  WhatWeManageSection,
  WhySection,
};

export const components = {
  achievements_section: dynamic(() => import("./AchievementsSection")),
  apartment_map_section: dynamic(() => import("./ApartmentMapSection")),
  apartments_section: dynamic(() => import("./ApartmentsSection")),
  app_section: dynamic(() => import("./AppSection")),
  award_section: dynamic(() => import("./AwardSection")),
  blog_section: dynamic(() => import("./BlogSection")),
  cities_section: dynamic(() => import("./CitiesSection")),
  city_map_section: dynamic(() => import("./CityMapSection")),
  company_usp_section: dynamic(() => import("./CompanyUspSection")),
  contact_form: dynamic(() => import("./ContactForm")),
  contacts_section: dynamic(() => import("./ContactsSection")),
  cta_section: dynamic(() => import("./CtaSection")),
  embed_section: dynamic(() => import("./EmbedSection")),
  faq_section: dynamic(() => import("./FaqSection")),
  form_section: dynamic(() => import("./FormSection")),
  form_with_calendly: dynamic(() => import("./FormWithCalendly")),
  gallery_section: dynamic(() => import("./GallerySection")),
  guide_image_section: dynamic(() => import("./GuideImageSection")),
  guide_location_section: dynamic(() => import("./GuideLocationSection")),
  guide_rating_section: dynamic(() => import("./GuideRatingSection")),
  hero_section: dynamic(() => import("./HeroSection")),
  image_section: dynamic(() => import("./ImageSection")),
  logo_cloud: dynamic(() => import("./LogoCloud")),
  pricing_section: dynamic(() => import("./PricingSection")),
  saas_section: dynamic(() => import("./SaasSection")),
  signup_cta: dynamic(() => import("./SignupCta")),
  split_image_section: dynamic(() => import("./SplitImageSection")),
  steps_section: dynamic(() => import("./StepsSection")),
  tabbed_images_section: dynamic(() => import("./TabbedImagesSection")),
  testimonials_section: dynamic(() => import("./TestimonialsSection")),
  text_section: dynamic(() => import("./TextSection")),
  text_usp_section: dynamic(() => import("./TextUspSection")),
  text_usp_short_section: dynamic(() => import("./TextUspShortSection")),
  toc_section: dynamic(() => import("./TocSection")),
  usp_section: dynamic(() => import("./UspSection")),
  values_section: dynamic(() => import("./ValuesSection")),
  what_we_manage_section: dynamic(() => import("./WhatWeManageSection")),
  why_section: dynamic(() => import("./WhySection")),
};
