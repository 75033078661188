import { Cities } from "@/components/Cities";
import { CTACard } from "@/components/CTACard";
import { Section, SectionDescription, SectionHeading } from "@/components/Section";
import { htmlSerializer } from "@/lib/htmlSerializer";
import { PrismicRichText } from "@prismicio/react";
import * as React from "react";

const CitiesSection = ({ slice }) => (
  <Section variant={slice.variation === "default" ? undefined : slice.variation}>
    <SectionHeading>{slice.primary.title}</SectionHeading>
    {slice.primary.description.length > 0 && (
      <SectionDescription>
        <PrismicRichText field={slice.primary.description} components={htmlSerializer} />
      </SectionDescription>
    )}
    <Cities mb={{ base: 10, md: 20 }} />
    <CTACard
      buttonLabel={slice.primary.ctaButton}
      buttonLink={slice.primary.ctaButtonLink.url}
      content={slice.primary.ctaContent}
    />
  </Section>
);

CitiesSection.displayName = CitiesSection;

export default CitiesSection;
