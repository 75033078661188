import { getHeadingId } from "@/utils/getHeadingId";
import type { ListProps } from "@chakra-ui/react";
import { List, ListItem, OrderedList } from "@chakra-ui/react";
import type { RTTextNodeBase } from "@prismicio/types";
import * as React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

type TOCContextState = {
  groupedHeadings: { heading: RTTextNodeBase; items: RTTextNodeBase[] }[];
};

export const TOCContext = React.createContext<TOCContextState>({
  groupedHeadings: [],
});

export type TableOfContentsProps = ListProps;

export const TableOfContents = (props: TableOfContentsProps) => {
  const { groupedHeadings } = React.useContext(TOCContext);

  return (
    <List spacing={4} {...props}>
      {groupedHeadings.map((groupedHeading) => {
        return (
          <ListItem key={groupedHeading.heading.text} color="almond">
            <AnchorLink
              offset="88px"
              href={`#${groupedHeading.heading.text ? getHeadingId(groupedHeading.heading.text) : ""}`}
            >
              {groupedHeading.heading.text}
            </AnchorLink>
            {groupedHeading.items.length > 0 ? (
              <OrderedList spacing={3} pt={3} pl={5}>
                {groupedHeading.items.map((item) => (
                  <ListItem key={item.text} color="gray.700" fontWeight="medium">
                    <AnchorLink offset="88px" href={`#${item.text ? getHeadingId(item.text) : ""}`}>
                      {item.text?.replace(/\d. /, "")}
                    </AnchorLink>
                  </ListItem>
                ))}
              </OrderedList>
            ) : null}
          </ListItem>
        );
      })}
    </List>
  );
};
