import { useProvidedStyles } from "@/hooks/useProvidedStyles";
import { htmlSerializer } from "@/lib/htmlSerializer";
import { Avatar, Box, Flex, Text } from "@chakra-ui/react";
import { PrismicRichText } from "@prismicio/react";
import * as React from "react";
import { Card, CardProps } from "../common/Card";

export type Testimonial = {
  image?: { url: string };
  title: string;
  name?: string;
  quote: React.ComponentProps<typeof PrismicRichText>["field"];
};

interface TestimonialCardProps extends CardProps {
  testimonial: Testimonial;
}

export const TestimonialCard = ({ testimonial, ...rest }: TestimonialCardProps) => {
  const styles = useProvidedStyles({ name: "card" });

  return (
    <Card sx={styles} as="figure" bg="cream" display="flex" flexDirection="column" {...rest}>
      <Box
        as="q"
        fontStyle="italic"
        mb={8}
        __css={{ quotes: '"“" "”" "‘" "’"', p: { display: "inline" } }}
        _before={{ content: "open-quote", display: "inline-block" }}
        _after={{ content: "close-quote", display: "inline-block" }}
      >
        <PrismicRichText field={testimonial.quote} components={htmlSerializer} />
      </Box>
      <Flex as="figcaption" alignItems="center">
        {testimonial.image && (
          <Avatar src={`${testimonial.image.url}&w=56`} name={testimonial.name} boxSize={14} mr={4} loading="lazy" />
        )}
        <Box>
          {testimonial.name && (
            <Text display="block" as="cite" fontStyle="normal" fontWeight="medium">
              {testimonial.name}
            </Text>
          )}
          <Text display="block" as="cite" fontStyle="normal" color="gray.600">
            {testimonial.title}
          </Text>
        </Box>
      </Flex>
    </Card>
  );
};
