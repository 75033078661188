import { Container } from "@/components/Container";
import { htmlSerializer } from "@/lib/htmlSerializer";
import { Box } from "@chakra-ui/react";
import { PrismicRichText } from "@prismicio/react";
import * as React from "react";

const TextSection = ({ slice }) => (
  <Box
    as="section"
    px={{ base: 4, md: 10, xl: 20 }}
    py={{ base: 4, md: 10, xl: 20 }}
    bg={slice.variation === "default" ? "white" : slice.variation}
    __css={{
      "p,ul,ol": {
        my: "1em",
      },
      "h1,h2": { mt: "2em", mb: "1em", lineHeight: 1 },
      "h3,h4,h5,h6": { mt: "1.25em", mb: "0.75em", lineHeight: 1 },
    }}
  >
    <Container maxW="75ch">
      <PrismicRichText field={slice.primary.content} components={htmlSerializer} />
    </Container>
  </Box>
);

TextSection.displayName = TextSection;

export default TextSection;
