import { Section, SectionDescription, SectionHeading } from "@/components/Section";
import { htmlSerializer } from "@/lib/htmlSerializer";
import { AspectRatio, Box, Flex } from "@chakra-ui/react";
import { PrismicRichText } from "@prismicio/react";
import * as React from "react";

const Embed = ({ embedHtml, ...rest }) => (
  <AspectRatio
    w="100%"
    maxW={{ base: "100%", md: "100%", xl: "524px" }}
    flexShrink={0}
    borderRadius="lg"
    overflow="hidden"
    ratio={16 / 9}
    {...rest}
  >
    <Flex
      h="100%"
      w="100%"
      __css={{ iframe: { width: "100%", height: "100%" } }}
      dangerouslySetInnerHTML={{ __html: embedHtml }}
    />
  </AspectRatio>
);

const EmbedSection = ({ slice }) => {
  const embedPosition = slice.variation.includes("left") ? "left" : "right";
  const sectionVariant = slice.variation.toLowerCase().includes("cream") ? "cream" : "white";

  return (
    <Section variant={sectionVariant}>
      <Flex flexDirection={{ base: "column", xl: "row" }}>
        <Embed
          embedHtml={slice.primary.embed.html}
          display={{ base: "none", xl: embedPosition === "left" ? "flex" : "none" }}
        />
        <Box
          ml={{ base: 0, xl: embedPosition === "left" ? 32 : 0 }}
          mr={{ base: 0, xl: embedPosition === "right" ? 32 : 0 }}
          mb={{ base: 16, xl: 0 }}
        >
          <SectionHeading>{slice.primary.title}</SectionHeading>
          <SectionDescription mb={0}>
            <PrismicRichText field={slice.primary.description} components={htmlSerializer} />
          </SectionDescription>
        </Box>
        <Embed
          embedHtml={slice.primary.embed.html}
          display={{ base: "flex", xl: embedPosition === "right" ? "flex" : "none" }}
        />
      </Flex>
    </Section>
  );
};

EmbedSection.displayName = EmbedSection;

export default EmbedSection;
