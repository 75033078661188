import { useStyles } from "@chakra-ui/react";

export const useProvidedStyles = ({ name }: { name: string }) => {
  try {
    const styles = useStyles();
    return name ? styles[name] : styles;
  } catch {
    return;
  }
};
