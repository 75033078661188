import { Card } from "@/components/common";
import { FadeInImage } from "@/components/FadeInImage";
import { useProvidedStyles } from "@/hooks/useProvidedStyles";
import { htmlSerializer } from "@/lib/htmlSerializer";
import type { GridProps } from "@chakra-ui/react";
import { Grid, Text } from "@chakra-ui/react";
import { PrismicRichText } from "@prismicio/react";
import * as React from "react";

interface ImageCardProps extends GridProps {
  description: React.ComponentProps<typeof PrismicRichText>["field"];
  imageUrl: string;
  title: string;
  imageSizes?: string;
}

export const ImageCard = ({ description, imageUrl, title, imageSizes, ...rest }: ImageCardProps) => {
  const styles = useProvidedStyles({ name: "card" });

  return (
    <Grid gridTemplateRows="160px 1fr" {...rest}>
      <FadeInImage
        src={imageUrl}
        sizes={imageSizes}
        h="160px"
        w="100%"
        alt=""
        borderBottomRadius="none"
        overflow="hidden"
      />
      <Card sx={styles} borderTopRadius="none">
        <Text as="h6" fontSize="18px" fontWeight="medium" mb={4}>
          {title}
        </Text>
        <PrismicRichText field={description} components={htmlSerializer} />
      </Card>
    </Grid>
  );
};
