import { AvailableApartments } from "@/components/AvailableApartments";
import { Section, SectionDescription, SectionHeading } from "@/components/Section";
import { htmlSerializer } from "@/lib/htmlSerializer";
import { PrismicRichText } from "@prismicio/react";
import { useRouter } from "next/router";
import * as React from "react";

const ApartmentsSection = ({ slice }) => {
  const router = useRouter();
  const { uid } = router.query;

  return (
    <Section variant={slice.variation === "default" ? undefined : slice.variation}>
      <SectionHeading>{slice.primary.title}</SectionHeading>
      <SectionDescription>
        <PrismicRichText field={slice.primary.description} components={htmlSerializer} />
      </SectionDescription>
      <AvailableApartments citySlug={uid} />
    </Section>
  );
};

ApartmentsSection.displayName = ApartmentsSection;

export default ApartmentsSection;
