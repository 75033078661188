import { PricingCard } from "@/components/PricingCard";
import { Section, SectionDescription, SectionHeading } from "@/components/Section";
import { htmlSerializer } from "@/lib/htmlSerializer";
import { Box, Grid } from "@chakra-ui/react";
import Image from "next/legacy/image";
import { PrismicRichText } from "@prismicio/react";
import * as React from "react";
import scribble6 from "../../../public/scribble6.png";
import scribble7 from "../../../public/scribble7.png";

const PricingSection = ({ slice }) => (
  <Section variant={slice.variation === "default" ? "white" : slice.variation} position="relative" overflow="hidden">
    <SectionHeading>{slice.primary.title}</SectionHeading>
    <SectionDescription>
      <PrismicRichText field={slice.primary.description} components={htmlSerializer} />
    </SectionDescription>
    <Box position="absolute" display={{ base: "none", lg: "block" }} top={{ lg: "-260px" }} right={{ lg: -20 }}>
      <Image src={scribble6} alt="" width={440} height={640} />
    </Box>
    <Box position="absolute" display={{ base: "none", lg: "block" }} top={{ lg: "220px" }} right={{ lg: "110px" }}>
      <Image src={scribble7} alt="" width={160} height={240} />
    </Box>
    <Grid gridTemplateColumns={{ base: "1fr", md: "repeat(2, 1fr)", xl: "repeat(3, 1fr)" }} gap={4}>
      {slice?.items?.map(({ pricingTier }) => (
        <PricingCard
          key={pricingTier.data.title}
          zIndex={1}
          pricingTier={{
            title: pricingTier.data.title,
            description: pricingTier.data.description,
            features: pricingTier.data.features,
          }}
        />
      ))}
    </Grid>
  </Section>
);

PricingSection.displayName = PricingSection;

export default PricingSection;
