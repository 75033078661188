import { Image } from "@/components/common";
import { htmlSerializer } from "@/lib/htmlSerializer";
import type { BoxProps } from "@chakra-ui/react";
import { Box, Text } from "@chakra-ui/react";
import { PrismicRichText } from "@prismicio/react";
import * as React from "react";

interface USPCardProps extends BoxProps {
  description: React.ComponentProps<typeof PrismicRichText>["field"];
  image: {
    dimensions: { width: number; height: number };
    url: string;
  };
  title: string;
}

export const USPCard = ({ description, image, title, ...rest }: USPCardProps) => {
  return (
    <Box {...rest}>
      <Image src={image.url} height="32px" width="32px" sizes="32px" alt="" mb={4} />
      <Text as="h6" fontSize="18px" fontWeight="medium" mb={4}>
        {title}
      </Text>
      <PrismicRichText field={description} components={htmlSerializer} />
    </Box>
  );
};
