import type { BoxProps } from "@chakra-ui/react";
import * as React from "react";
import { Link } from "../common/Link";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TrustBoxProps extends BoxProps {}

export const TrustBox = () => {
  // // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null);

  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if ((window as any).Trustpilot && (window as any).Trustpilot.loadFromElement && ref.current) {
      (window as any).Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <div
      id="trustbox"
      ref={ref} // We need a reference to this element to load the TrustBox in the effect.
      className="trustpilot-widget"
      data-locale="en-US"
      data-template-id="5419b6a8b0d04a076446a9ad"
      data-businessunit-id="59c50ce70000ff0005abc80b"
      data-style-height="24px"
      data-style-width="100%"
      data-theme="light"
      data-stars="1,2,3,4,5"
      data-no-reviews="hide"
      data-scroll-to-list="true"
      data-allow-robots="true"
    >
      <Link href="https://www.trustpilot.com/review/joinlifex.com" target="_blank" rel="noopener noreferrer">
        See our reviews on TrustPilot
      </Link>
    </div>
  );
};
