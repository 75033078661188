import { htmlSerializer } from "@/lib/htmlSerializer";
import type { ListItemProps } from "@chakra-ui/react";
import { Box, ListItem, Text } from "@chakra-ui/react";
import { PrismicRichText } from "@prismicio/react";
import * as React from "react";

interface WhyCardProps {
  title: string;
  description: React.ComponentProps<typeof PrismicRichText>["field"];
}

export const WhyCard = ({ title, description, ...rest }: WhyCardProps & ListItemProps) => {
  return (
    <ListItem ml={1} {...rest}>
      <Text ml={4} as="h4" mb={4} fontSize="lg" fontWeight="500" display="inline-block">
        {title}
      </Text>
      <Box ml={4}>
        <PrismicRichText field={description} components={htmlSerializer} />
      </Box>
    </ListItem>
  );
};
