import { Image } from "@/components/common/Image";
import { Section, SectionDescription, SectionHeading } from "@/components/Section";
import { TestimonialCard } from "@/components/TestimonialCard";
import { htmlSerializer } from "@/lib/htmlSerializer";
import { Box, Flex, Grid } from "@chakra-ui/react";
import { PrismicRichText } from "@prismicio/react";
import * as React from "react";

const AwardSection = ({ slice }) => {
  const AwardImage = () => (
    <Image
      src="/award.png"
      alt="award"
      width={{ base: "164px", xl: "300px" }}
      sizes={{ base: "164px", xl: "300px" }}
      height={{ base: "164px", xl: "300px" }}
    />
  );

  return (
    <Section variant={slice.variation === "default" ? undefined : slice.variation}>
      <Grid gridTemplateColumns={{ base: "1fr", md: "1fr max-content" }} columnGap={32}>
        <Flex display={{ md: "none" }}>
          <AwardImage />
        </Flex>
        <Box mt={{ base: 16, md: 0 }}>
          <SectionHeading>{slice.primary.title}</SectionHeading>
          <SectionDescription>
            <PrismicRichText field={slice.primary.description} components={htmlSerializer} />
          </SectionDescription>
          <TestimonialCard
            testimonial={slice.primary.testimonial.data}
            gridColumn="1 / span 1"
            display={{ base: "none", xl: "flex" }}
          />
        </Box>
        <Flex display={{ base: "none", md: "flex" }} justifyContent="center" alignItems="flex-start">
          <AwardImage />
        </Flex>
      </Grid>
      <TestimonialCard testimonial={slice.primary.testimonial.data} display={{ base: "flex", xl: "none" }} />
    </Section>
  );
};

AwardSection.displayName = AwardSection;

export default AwardSection;
