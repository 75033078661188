import type { FadeInImageProps } from "@/components/FadeInImage";
import { FadeInImage } from "@/components/FadeInImage";
import { Box, Grid, GridProps, Icon, useBreakpointValue } from "@chakra-ui/react";
import "photoswipe/dist/photoswipe.css";
import { Ref } from "react";
import { Gallery, Item } from "react-photoswipe-gallery";
import { CarouselButton } from "../Carousel/Carousel";
import { ChevronLeft, ChevronRight } from "@/icons";
import * as React from "react";

interface HorizontalScrollGalleryProps extends GridProps {
  images: {
    alt: string;
    url: string;
    height?: number;
    width?: number;
  }[];
  height?: FadeInImageProps["height"];
  width?: { [key: string]: string };
  withLightbox?: boolean;
}

export const HorizontalScrollGallery = ({
  images,
  height = { base: "228px", md: "368px", xl: "300px" },
  width = { base: "312px", md: "512px", xl: "416px" },
  withLightbox = false,
  ...rest
}: HorizontalScrollGalleryProps) => {
  const [showLeftButton, setShowLeftButton] = React.useState(false);
  const [showRightButton, setShowRightButton] = React.useState(true);
  const scrollContainerRef = React.useRef<HTMLDivElement>(null);

  const imageWidth = useBreakpointValue(
    {
      base: 720,
      md: 960,
      lg: 1920,
    },
    "base",
  );

  const updateButtonVisibility = React.useCallback(() => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      setShowLeftButton(scrollLeft > 0);
      setShowRightButton(scrollLeft + clientWidth < scrollWidth);
    }
  }, []);

  React.useEffect(
    function () {
      const scrollContainer = scrollContainerRef.current;
      if (scrollContainer) {
        scrollContainer.addEventListener("scroll", updateButtonVisibility);
        updateButtonVisibility();
        return () => scrollContainer.removeEventListener("scroll", updateButtonVisibility);
      }

      return;
    },
    [updateButtonVisibility],
  );

  const handleScroll = React.useCallback((direction: "left" | "right") => {
    if (scrollContainerRef.current) {
      const scrollAmount = scrollContainerRef.current.clientWidth;
      scrollContainerRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <Box
      position="relative"
      _hover={{
        ".carousel-button": {
          opacity: 0.9,
        },
      }}
    >
      <Grid
        ref={scrollContainerRef}
        css={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
        gridGap={4}
        overflowX="scroll"
        _before={{ content: "''" }}
        _after={{ content: "''" }}
        {...rest}
      >
        <Gallery>
          {images.map((image, index) => {
            if (withLightbox) {
              return (
                <Item
                  key={`item-${index}`}
                  original={`${image.url.split("?")[0]}?q=85&w=${imageWidth}&fm=webp`}
                  thumbnail={image.url}
                  height={image.height}
                  width={image.width}
                >
                  {({ ref, open }) => (
                    <FadeInImage
                      ref={ref as Ref<HTMLDivElement>}
                      onClick={open}
                      src={image.url}
                      alt={image.alt ?? ""}
                      height={height}
                      width={width}
                      sizes={width}
                      zIndex={1}
                      borderRadius="lg"
                      overflow="hidden"
                    />
                  )}
                </Item>
              );
            }

            return (
              <FadeInImage
                key={`${image.url}-${index}`}
                src={image.url}
                alt={image.alt ?? ""}
                height={height}
                width={width}
                sizes={width}
                zIndex={1}
                borderRadius="lg"
                overflow="hidden"
              />
            );
          })}
        </Gallery>
      </Grid>
      {showLeftButton && (
        <CarouselButton
          aria-label="Scroll to previous images"
          icon={<Icon as={ChevronLeft} h={6} w={6} />}
          left={4}
          onClick={() => handleScroll("left")}
          opacity={{ base: 1 }}
        />
      )}
      {showRightButton && (
        <CarouselButton
          aria-label="Scroll to next images"
          icon={<Icon as={ChevronRight} h={6} w={6} />}
          right={4}
          onClick={() => handleScroll("right")}
          opacity={{ base: 1 }}
        />
      )}
    </Box>
  );
};
