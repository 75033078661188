import { useProvidedStyles } from "@/hooks/useProvidedStyles";
import { Text, Wrap, WrapItem } from "@chakra-ui/react";
import * as React from "react";
import { Card } from "../common";
import type { CardProps } from "../common/Card";

interface USPsCardProps {
  title: string;
  usps: string[];
}

export const USPsCard = ({ title, usps, ...rest }: USPsCardProps & CardProps) => {
  const styles = useProvidedStyles({ name: "card" });

  return (
    <Card sx={styles} {...rest}>
      <Text as="h4" mb={6} fontSize="lg" fontWeight="500">
        {title}
      </Text>
      <Wrap direction={["column", "row"]} spacing={5}>
        {usps.map((usp) => {
          return (
            <WrapItem
              px={3}
              py={2}
              fontWeight="500"
              borderRadius="base"
              maxW="max-content"
              maxH="max-content"
              bg="#E9E8E7"
              fontSize="sm"
              key={usp}
            >
              {usp.trim()}
            </WrapItem>
          );
        })}
      </Wrap>
    </Card>
  );
};
