import { Box, useMultiStyleConfig, useStyleConfig } from "@chakra-ui/react";

interface HubSpotFormContainerProps {
  id: string;
}

export const HubSpotFormContainer = ({ id }: HubSpotFormContainerProps) => {
  const inputStyles = useMultiStyleConfig("Input", { variant: "erik" });
  const buttonStyle = useStyleConfig("Button", { variant: "primary" });
  const labelStyle = useStyleConfig("FormLabel");
  const selectStyle = useMultiStyleConfig("Select", {});
  const textareaStyle = useStyleConfig("Textarea");
  const checkboxStyle = useMultiStyleConfig("Checkbox", {});

  return (
    <Box
      id={id}
      __css={{
        fontFamily: "Inter",
        form: {
          display: "grid",
          gridRowGap: 5,
          maxW: "2xl",
        },
        input: {
          width: "100% !important",
        },
        "input,input[type=text],input[type=number],input[type=email],input[type=tel]": {
          ...inputStyles.field,
          width: "100% !important",
        },
        "input[type=submit]": {
          ...buttonStyle,
          cursor: "pointer",
          maxWidth: "max-content",
          mt: 10,
          mb: 0,
          borderWidth: 0,
        },
        label: {
          ...labelStyle,
          display: "block",
        },
        select: {
          ...selectStyle.field,
          borderColor: "gray.400",
          backgroundSize: "16px",
          backgroundImage: "url('/chevron-down.svg')",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "calc(100% - 0.5rem) center",
          width: "100% !important",
        },
        textarea: {
          ...textareaStyle,
          borderColor: "gray.400",
          _placeholder: {
            color: "gray.500",
          },
          width: "100% !important",
        },
        p: {
          mb: 5,
        },
        li: {
          listStyleType: "none",
          "&.hs-form-booleancheckbox": {
            label: {
              mb: 4,
              display: "flex",
              alignItems: "center",
              ...checkboxStyle.label,
            },
          },
        },
        "input[type=checkbox]": {
          ...checkboxStyle.control,
          accentColor: "almond",
        },
        ".hs-error-msg": {
          color: "red.600",
        },
        fieldset: {
          maxWidth: "100% !important",
        },
      }}
    />
  );
};
