import { BrandedPin } from "@/components/BrandedPin";
import { Map } from "@/components/Map";
import { Section, SectionDescription } from "@/components/Section";
import { htmlSerializer } from "@/lib/htmlSerializer";
import { Box, Text } from "@chakra-ui/react";
import { PrismicRichText } from "@prismicio/react";
import * as React from "react";

const GuideLocationSection = ({ slice }) => {
  const sectionVariant = slice.variation.toLowerCase().includes("cream") ? "cream" : "white";

  return (
    <Section
      variant={sectionVariant}
      containerProps={{
        display: { base: "flex", xl: "grid" },
        flexDirection: { base: "column-reverse", xl: "row" },
        gridTemplateColumns: { base: "1fr", xl: "1fr 416px" },
        gridGap: { base: "80px", xl: "124px" },
      }}
      pt={0}
      mt={{ base: 20, xl: "140px" }}
      mb={20}
      pb={0}
    >
      <SectionDescription mb={0}>
        <PrismicRichText field={slice.primary.body} components={htmlSerializer} />
      </SectionDescription>
      <Box
        gridGap={4}
        display="grid"
        gridTemplateColumns={{ base: "1fr", md: "1fr 1fr", xl: "1fr" }}
        gridAutoRows="max-content"
      >
        <Map
          center={{ lat: slice.primary.location.latitude, lng: slice.primary.location.longitude }}
          zoom={14}
          height={{ base: "344px", md: "416px" }}
          width="100%"
          borderRadius="md"
          overflow="hidden"
          gridColumn="1 / -1"
        >
          <BrandedPin lat={slice.primary.location.latitude} lng={slice.primary.location.longitude} />
        </Map>
        {slice.items.map((item, index) => {
          return (
            <Box
              key={item.title}
              gridColumn={index === 0 ? "1 / -1" : "span 1"}
              p={10}
              borderRadius="md"
              borderStyle="solid"
              borderColor="gray.400"
              borderWidth="1px"
            >
              <Text fontSize="sm" color="gray.600" mb={4}>
                {item.title}
              </Text>
              <PrismicRichText field={item.description} components={htmlSerializer} />
            </Box>
          );
        })}
      </Box>
    </Section>
  );
};

GuideLocationSection.displayName = GuideLocationSection;

export default GuideLocationSection;
