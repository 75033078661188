import { Image } from "@/components/common";
import type { FlexProps } from "@chakra-ui/react";
import { Box, Flex, Text, Link } from "@chakra-ui/react";
import * as React from "react";

interface ContactCardProps extends FlexProps {
  contact: {
    title: string;
    name: string;
    email: string;
    phoneNumber: string;
    image: {
      url: string;
    };
  };
}

export const ContactCard = ({ contact, ...rest }: ContactCardProps) => {
  return (
    <Flex {...rest}>
      <Image
        src={contact.image.url}
        alt=""
        h="154px"
        w="154px"
        minW="154px"
        minH="154px"
        borderRadius="50%"
        overflow="hidden"
        position="relative"
        sx={{
          img: {
            filter: "grayscale(100%)",
          },
        }}
        mr={6}
      >
        <Box opacity={0.2} bg="almond" position="absolute" h="100%" w="100%" inset={0} />
      </Image>
      <Flex justifyContent="center" flexDirection="column">
        <Text fontWeight="medium" fontSize="sm" color="gray.500" mb={2}>
          {contact.title}
        </Text>
        <Text fontWeight="medium" fontSize="lg" mb={2}>
          {contact.name}
        </Text>
        <Link href={`mailto:${contact.email}`} fontWeight="medium" color="almond" mb={2}>
          {contact.email}
        </Link>
        <Link href={`tel:${contact.phoneNumber}`} fontWeight="medium" color="almond">
          {contact.phoneNumber}
        </Link>
      </Flex>
    </Flex>
  );
};
