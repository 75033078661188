import { FormLoading } from "@/components/FormLoading";
import { HubSpotFormContainer } from "@/components/HubSpotFormContainer";
import { Section, SectionDescription, SectionHeading } from "@/components/Section";
import { useHubspotForm } from "@/hooks/useHubspotForm";
import { htmlSerializer } from "@/lib/htmlSerializer";
import { PrismicRichText } from "@prismicio/react";
import slugify from "slugify";

const FormSection = ({ slice }) => {
  const id = slice.primary.title ? slugify(slice.primary.title.toLowerCase(), { remove: /[^\d\s:A-Za-z]/ }) : null;

  const { loaded } = useHubspotForm({
    portalId: slice.primary.portalId ?? "5105035",
    formId: slice.primary.formId,
    target: `#${id}`,
  });

  return (
    <Section variant={slice.variation === "default" ? "white" : slice.variation} position="relative" overflow="hidden">
      {slice.primary.title ? <SectionHeading>{slice.primary.title}</SectionHeading> : null}
      <SectionDescription>
        <PrismicRichText field={slice.primary.description} components={htmlSerializer} />
      </SectionDescription>
      {!loaded && <FormLoading />}
      <HubSpotFormContainer id={id ?? slice.primary.formId} />
    </Section>
  );
};

FormSection.displayName = FormSection;

export default FormSection;
