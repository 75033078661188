import { useProvidedStyles } from "@/hooks/useProvidedStyles";
import { Section } from "@/components/Section";
import { Box, Text, HStack, Image, Progress } from "@chakra-ui/react";
import * as React from "react";
import { Card } from "@/components/common";

const RatingCard = ({ items }) => {
  const styles = useProvidedStyles({ name: "card" });

  return (
    <Card
      sx={{ bg: { base: "transparent", md: styles.bg } }}
      display="grid"
      gridTemplateColumns={{ base: "1fr", xl: "repeat(3, 1fr)" }}
      gridRowGap={6}
      gridColumnGap={24}
      padding={{ base: 0, md: 10 }}
      px={{ base: 6, md: 10 }}
    >
      {items.map(({ icon, label, rating }) => {
        return (
          <Box
            key={label}
            display="grid"
            gridTemplateColumns={{ base: "1fr 80px", sm: "1fr 100px", md: "1fr 140px" }}
            alignItems="center"
            gap={4}
          >
            <HStack alignItems="center" gap={4} flex={1}>
              <Image src={icon.url} h={6} w={6} />
              <Text fontSize="md" fontWeight="medium" isTruncated>
                {label}
              </Text>
            </HStack>
            <Progress
              colorScheme="orange"
              borderRadius="100px"
              w="100%"
              h="4px"
              value={rating}
              max={10}
              background="gray.400"
            />
          </Box>
        );
      })}
    </Card>
  );
};

const GuideRatingSection = ({ slice }) => {
  const sectionVariant = slice.variation.toLowerCase().includes("cream") ? "cream" : "white";

  return (
    <Section variant={sectionVariant} pt={{ base: 10, xl: 20 }} pb={{ base: 10, xl: 20 }}>
      <RatingCard items={slice.items} />
    </Section>
  );
};

GuideRatingSection.displayName = GuideRatingSection;

export default GuideRatingSection;
