import { Container } from "@/components/Container";
import { SectionDescription, SectionHeading } from "@/components/Section";
import { TableOfContents } from "@/components/TableOfContents";
import { htmlSerializer } from "@/lib/htmlSerializer";
import { Box } from "@chakra-ui/react";
import { PrismicRichText } from "@prismicio/react";
import * as React from "react";

const TocSection = ({ slice }) => (
  <Box
    as="section"
    px={{ base: 4, md: 10, xl: 20 }}
    py={{ base: 4, md: 10, xl: 20 }}
    bg={slice.variation === "default" ? "white" : slice.variation}
  >
    <Container maxW="75ch">
      <SectionHeading mb={(slice.primary.description ?? []).length > 0 ? 2 : 6}>{slice.primary.title}</SectionHeading>
      {(slice.primary.description ?? []).length > 0 && (
        <SectionDescription>
          <PrismicRichText field={slice.primary.description} components={htmlSerializer} />
        </SectionDescription>
      )}
      <TableOfContents />
    </Container>
  </Box>
);

TocSection.displayName = TocSection;

export default TocSection;
