import { Container } from "@/components/Container";
import { SectionHeading } from "@/components/Section";
import { Close, Plus } from "@/icons";
import { htmlSerializer } from "@/lib/htmlSerializer";
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Icon, Text } from "@chakra-ui/react";
import type { Content } from "@prismicio/client";
import { PrismicLink, PrismicRichText, type SliceComponentProps } from "@prismicio/react";
import * as React from "react";

export function isFAQ(item: Content.FaqDocument | { link_type: string }): item is Content.FaqDocument {
  return "id" in item;
}

const FaqSection: React.FC<SliceComponentProps<Content.FaqSectionSlice>> = ({ slice }) => {
  const hasCta = Boolean(slice.primary.cta && slice.primary.ctaText);

  return (
    <Box
      as="section"
      px={{ base: 4, md: 10, xl: 20 }}
      py={{ base: 20, md: 36 }}
      bg={slice.variation === "cream" ? "cream" : undefined}
    >
      <Container
        maxW="75ch"
        sx={{
          a: {
            color: "almond",
            px: 4,
          },
        }}
      >
        <SectionHeading mb={10}>{slice.primary.title}</SectionHeading>
        <Accordion allowMultiple mb={hasCta ? 4 : 0}>
          {slice.items.map((item) => {
            if (isFAQ(item.faq)) {
              const faq = item.faq;

              if (!faq.data?.questionName || !faq.data?.acceptedAnswerText) {
                return null;
              }

              return (
                <AccordionItem key={faq.id}>
                  {({ isExpanded }) => (
                    <>
                      <Text as="h2">
                        <AccordionButton p={4} fontWeight="medium">
                          <Box flex="1" textAlign="left">
                            <PrismicRichText field={faq.data.questionName} components={htmlSerializer} />
                          </Box>
                          <Icon boxSize={6} as={isExpanded ? Close : Plus} />
                        </AccordionButton>
                      </Text>
                      <AccordionPanel pb={4}>
                        <PrismicRichText field={faq.data.acceptedAnswerText} components={htmlSerializer} />
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              );
            }

            return (
              <AccordionItem key={item.question}>
                {({ isExpanded }) => (
                  <>
                    <Text as="h2">
                      <AccordionButton p={4} fontWeight="medium">
                        <Box flex="1" textAlign="left">
                          {item.question}
                        </Box>
                        <Icon boxSize={6} as={isExpanded ? Close : Plus} />
                      </AccordionButton>
                    </Text>
                    <AccordionPanel pb={4}>
                      <PrismicRichText field={item.answer} components={htmlSerializer} />
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            );
          })}
        </Accordion>
        {hasCta && <PrismicLink field={slice.primary.cta}>{slice.primary.ctaText}</PrismicLink>}
      </Container>
    </Box>
  );
};

export default FaqSection;
