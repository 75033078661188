import { AvailableRoomsInCityDocument } from "@/generated/graphql";
import { AMPLITUDE_EVENTS, logEvent } from "@/lib/amplitude";
import { useGraphqlQuery } from "@/lib/useGraphql";
import { Text } from "@chakra-ui/react";
import { gql } from "graphql-tag";
import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import { CTACard, CTACardProps } from "../CTACard/CTACard";

export const AVAILABLE_APARTMENTS_IN_CITY = gql`
  query AvailableRoomsInCity($citySlug: String!) {
    cityBySlug(slug: $citySlug) {
      id
      name
      availableUnits(filter: { published: true })
    }
  }
`;

interface AvailableRoomsCardProps {
  citySlug: string;
}

export const AvailableRoomsCard = ({
  citySlug,
  ...rest
}: AvailableRoomsCardProps & Omit<CTACardProps, "buttonLink" | "buttonLabel" | "buttonVariant" | "content">) => {
  const { t } = useTranslation("common");

  const { data } = useGraphqlQuery(AvailableRoomsInCityDocument, {
    citySlug,
  });

  const numAvailable = data?.cityBySlug?.availableUnits ?? 0;

  const buttonClick = () => {
    logEvent(numAvailable === 0 ? AMPLITUDE_EVENTS.subscribeButtonClick : AMPLITUDE_EVENTS.browseButtonClick, {
      origin: "Available rooms card",
      city: citySlug,
    });
  };

  return (
    <CTACard
      buttonLink={
        numAvailable === 0
          ? { pathname: "/[uid]/subscribe", query: { uid: citySlug } }
          : { pathname: "/[uid]/homes", query: { uid: citySlug } }
      }
      buttonLabel={t("available-options.button", { count: numAvailable ?? 0 })}
      buttonVariant="primary"
      onButtonClick={buttonClick}
      content={
        <Text fontWeight="medium" maxW="70ch">
          <Trans
            i18nKey="common:available-options"
            components={{
              options: <Text color="almond" as="span" />,
              city: <Text as="span" textTransform="capitalize" />,
            }}
            values={{ citySlug, count: numAvailable ?? 0 }}
          />
        </Text>
      }
      {...rest}
    />
  );
};
